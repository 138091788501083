.listing-template .plone-item-card .listing-dates-wrapper {
  padding-top: 9px;
  padding-bottom: 9px;
}
.listing-template .plone-item-card .hero-dates {
  color: #b6a782;
  font-size: 17px;
}
.listing-template .plone-item-card .expired .hero-dates {
  text-decoration: line-through;
}
.listing-template .plone-item-card .plone-item-description {
  color: #575756 !important;
}
.listing-template .plone-item-card .plone-item-descriptionspan {
  color: #575756 !important;
}
@media screen and (max-width: 768px) {
  .multiple-content-view .listing-template .column.listings .listing-column {
    padding-bottom: 0.35rem !important;
  }
}
.view-editview .block-editor-listing {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
