.view-viewview.subcategory-page h1.documentFirstHeading {
  margin-top: 80px !important;
}
@media screen and (min-width: 992px) {
}
.view-viewview.subcategory-page .empty-description-wrapper {
  height: 15px;
}
@media screen and (min-width: 992px) {
  .view-viewview.subcategory-page .empty-description-wrapper {
    height: 125px;
  }
}
.view-viewview.subcategory-page .listing-template {
  padding-left: 0px;
  padding-right: 0px;
}
@media screen and (min-width: 786px) and (max-width: 992px) {
  .view-viewview.subcategory-page .listing-template {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 768px) {
  .view-viewview.subcategory-page .plone-item-card {
    margin-top: 10px !important;
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 992px) {
  .view-viewview.subcategory-page .plone-item-card {
    margin-top: 9px !important;
    margin-bottom: 23px !important;
  }
}
.view-viewview.subcategory-page .subcategories {
  margin-top: 40px;
  margin-bottom: 0px;
}
@media screen and (min-width: 786px) and (max-width: 992px) {
  .view-viewview.subcategory-page .subcategories {
    margin-top: 18px !important;
  }
}
@media screen and (min-width: 992px) {
  .view-viewview.subcategory-page .subcategories {
    margin-top: 39px;
  }
}
.view-viewview.subcategory-page .subcategories .grid.listings {
  width: 100%;
  margin: 0%;
}
@media screen and (min-width: 992px) {
  .view-viewview.subcategory-page .subcategories .grid.listings {
    flex-wrap: nowrap;
  }
}
.view-viewview.subcategory-page .subcategories .grid.listings .column.listing-column {
  min-width: 0;
  flex-shrink: 1;
  flex-grow: 1;
  padding: 0px 5px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .view-viewview.subcategory-page .subcategories .grid.listings .column.listing-column {
    padding: 16px 20px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .view-viewview.subcategory-page .subcategories .grid.listings .column.listing-column {
    width: 50% !important;
  }
}
@media screen and (min-width: 992px) {
  .view-viewview.subcategory-page .subcategories .grid.listings .column.listing-column {
    width: auto !important;
  }
}
.view-viewview.subcategory-page .subcategories .grid.listings .column.listing-column img {
  height: 100% !important;
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: unset;
}
.view-editview .block-editor-listing {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
