.block.listing.verwachtsliderview {
  margin-top: 48px;
  margin-bottom: 0px;
}
@media screen and (min-width: 1400px) {
  .block.listing.verwachtsliderview {
    margin-top: 64px;
    margin-bottom: 0px;
  }
}
.view-viewview .block.listing.verwachtsliderview {
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw !important;
  max-width: initial !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
}
@media screen and (min-width: 992px) {
  .verwacht-slider-template {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.verwacht-slider-template .listing-slider > div {
  width: 100%;
}
@media screen and (min-width: 992px) {
  .verwacht-slider-template .content-wrapper {
    padding-bottom: 10px;
  }
}
.verwacht-slider-template .content {
  height: 120vw;
  min-height: 85vw;
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-left: 64px;
  padding-right: 63px;
  margin-top: 28px;
  margin-bottom: 35px;
}
@media screen and (min-width: 470px) {
  .verwacht-slider-template .content {
    padding-left: 64px;
    padding-right: 63px;
    margin-top: 30px;
  }
}
@media screen and (min-width: 768px) {
  .verwacht-slider-template .content {
    height: 82vw;
    padding-left: 75px;
    padding-right: 75px;
    margin-top: 0px;
  }
}
@media screen and (min-width: 992px) {
  .verwacht-slider-template .content {
    height: 25vw;
    min-height: 300px;
    flex-direction: row;
    padding-left: 82.5px;
    padding-right: 82.5px;
  }
}
@media screen and (min-width: 1480px) {
  .verwacht-slider-template .content {
    padding-left: 141px;
    padding-right: 141px;
  }
}
.verwacht-slider-template .card-left-part {
  width: 100%;
}
@media screen and (min-width: 992px) {
  .verwacht-slider-template .card-left-part {
    width: calc(40% + 1px);
  }
}
@media screen and (min-width: 1480px) {
  .verwacht-slider-template .card-left-part {
    margin-left: -3px;
  }
}
.verwacht-slider-template .card-right-part {
  width: 100%;
}
@media screen and (min-width: 992px) {
  .verwacht-slider-template .card-right-part {
    width: 60%;
    padding: 0 20px ;
  }
}
.verwacht-slider-template .listing-image {
  width: 100%;
}
.verwacht-slider-template .listing-image img {
  width: 100%;
  max-width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  aspect-ratio: 1.5;
}
.verwacht-slider-template .buttons {
  position: absolute;
  z-index: 0;
  top: calc(50% - 37px);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .verwacht-slider-template .buttons {
    top: calc(50% - 45px);
  }
}
.verwacht-slider-template button > .icon {
  background-color: transparent;
}
.verwacht-slider-template .left-button {
  position: absolute;
  z-index: 0;
  top: calc(50% - 16px);
  left: 6.5px;
  margin-top: 1px;
  margin-left: 6px;
  width: 16px;
  height: 30px;
  background-color: transparent;
  border: none;
  z-index: 1;
}
.verwacht-slider-template .left-button svg {
  fill: var(--bonnefanten-primary);
  height: 42.5px;
  opacity: 0.75;
  transition: opacity 0.1s ease-in;
}
.verwacht-slider-template .left-button svg:hover {
  opacity: 1;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .verwacht-slider-template .left-button {
    top: calc(50% - 60px);
    left: 6.5px;
    margin-top: 1px;
    margin-left: 14px;
    width: 16px;
  }
}
@media screen and (min-width: 768px) {
  .verwacht-slider-template .left-button {
    top: calc(50% - 45px);
    width: 62px;
    margin-left: 10px;
    left: 10px;
  }
}
@media screen and (min-width: 992px) {
  .verwacht-slider-template .left-button {
    top: calc(50% - 7px);
    left: 22px;
  }
}
@media screen and (min-width: 1480px) {
  .verwacht-slider-template .left-button {
    top: calc(50% - 14px);
    left: 20px;
    width: 70px;
    height: 85px;
    margin-left: 64px;
  }
}
.verwacht-slider-template .right-button {
  position: absolute;
  z-index: 0;
  top: calc(50% - 16px);
  right: 35px;
  z-index: 5;
  margin-top: 1px;
  margin-right: 6px;
  width: 16px;
  height: 30px;
  background-color: transparent;
  border: none;
}
.verwacht-slider-template .right-button svg {
  fill: var(--bonnefanten-primary);
  height: 42.5px;
  opacity: 0.75;
  transition: opacity 0.1s ease-in;
}
.verwacht-slider-template .right-button svg:hover {
  opacity: 1;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .verwacht-slider-template .right-button {
    top: calc(50% - 60px);
    right: 42px;
    margin-right: -2px;
    width: 16px;
  }
}
@media screen and (min-width: 768px) {
  .verwacht-slider-template .right-button {
    top: calc(50% - 45px);
    margin-left: 10px;
    right: 18px;
    width: 62px;
  }
}
@media screen and (min-width: 992px) {
  .verwacht-slider-template .right-button {
    top: calc(50% - 7px);
    right: 32px;
  }
}
@media screen and (min-width: 1480px) {
  .verwacht-slider-template .right-button {
    top: calc(50% - 14px);
    right: 26px;
    width: 70px;
    height: 85px;
    margin-right: 58px;
  }
}
.verwacht-slider-template .text-button.btn-block.more-info {
  line-height: 25px !important;
  margin-right: 15px !important;
  padding: 5px 14px !important;
  font-size: 16px !important;
  visibility: hidden !important;
}
.verwacht-slider-template .text-button.btn-block.more-info a {
  text-decoration: none !important;
  color: var(--bonnefanten-bg) !important;
}
@media screen and (min-width: 768px) {
  .verwacht-slider-template .text-button.btn-block.more-info {
    font-size: 19px !important;
  }
}
@media screen and (min-width: 992px) {
  .verwacht-slider-template .text-button.btn-block.more-info {
    visibility: visible !important;
  }
}
.verwacht-slider-template .text-button.btn-block:hover {
  background-color: #B6A782 !important;
  border-color: #B6A782 !important;
  font-size: 19px;
}
.verwacht-slider-template .plone-item-title {
  margin-top: 0px;
}
@media screen and (max-width: 768px) {
  .verwacht-slider-template .plone-item-title {
    margin-bottom: 0px;
  }
}
.verwacht-slider-template .plone-item-title a {
  border-bottom: 0px !important;
  text-decoration: none !important;
}
.verwacht-slider-template .plone-item-title a:hover {
  text-decoration: underline !important;
  -webkit-text-decoration-color: var(--bonnefanten-primary) !important;
          text-decoration-color: var(--bonnefanten-primary) !important;
  text-decoration-thickness: 2px !important;
  text-underline-offset: 3px !important;
}
.verwacht-slider-template .plone-item-title h2 {
  border-bottom: 1px solid transparent;
  color: var(--bonnefanten-primary) !important;
  font-family: 'StyreneBold', Arial, sans-serif;
  margin-top: 15px !important;
  font-weight: 400;
  font-size: 20px;
  line-height: 2.5vw;
  letter-spacing: 0em;
  line-height: 30px;
  text-transform: none;
  margin-left: -0.5px !important;
  margin-bottom: 0px !important;
}
@media screen and (min-width: 992px) {
  .verwacht-slider-template .plone-item-title h2 {
    font-size: 1.8vw;
    margin-top: 12px !important;
    margin-left: 0px;
    margin-bottom: 16px !important;
    max-width: 75%;
  }
}
@media screen and (min-width: 1480px) {
  .verwacht-slider-template .plone-item-title h2 {
    line-height: 2.5vw;
    margin-top: 10px !important;
    font-size: 30px;
  }
}
.verwacht-slider-template .hero-dates {
  font-family: 'StyreneRegular', Arial, sans-serif;
  letter-spacing: 0em;
  color: #b6a782 !important;
  font-size: 18px !important;
  line-height: 18px !important;
}
.verwacht-slider-template .event-label {
  padding-top: 18px !important;
}
@media screen and (min-width: 992px) {
  .verwacht-slider-template .event-label {
    padding-top: 0px !important;
  }
}
@media screen and (min-width: 992px) {
  .verwacht-slider-template .description {
    margin-top: 24px !important;
  }
}
.verwacht-slider-template .description p {
  color: #b6a782 !important;
  font-size: 17px;
  line-height: 25px !important;
  overflow: hidden;
  margin-bottom: 25px !important;
}
@media screen and (max-width: 992px) {
  .verwacht-slider-template .description p {
    margin-top: 3px !important;
  }
}
@media screen and (min-width: 992px) {
  .verwacht-slider-template .description p {
    font-size: 19px !important;
    max-width: 75%;
  }
}
.verwacht-slider-template .nutezien-header {
  margin-top: 15px;
  margin-bottom: 42px;
  margin-left: 11px;
}
@media screen and (min-width: 768px) {
  .verwacht-slider-template .nutezien-header {
    margin-bottom: 75px;
  }
}
@media screen and (min-width: 992px) {
  .verwacht-slider-template .nutezien-header {
    margin-bottom: 52px;
    margin-left: 11px;
  }
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 1480px) {
  .verwacht-slider-template .nutezien-header {
    margin-bottom: 88px;
  }
}
.verwacht-slider-template .nutezien-header h3 {
  line-height: 1.2;
  margin-top: 8px;
  color: #b6a782 !important;
  font-family: 'BonnefantenBlock', Arial, sans-serif;
  text-align: center;
  margin-top: 25px;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0em;
  padding-right: 11px;
  font-size: 11vw !important;
  line-height: 10vw;
}
@media screen and (min-width: 768px) {
  .verwacht-slider-template .nutezien-header h3 {
    font-size: 11vw !important;
    line-height: 10vw;
  }
}
@media screen and (min-width: 992px) {
  .verwacht-slider-template .nutezien-header h3 {
    font-size: 4vw !important;
    line-height: 4.7vw;
  }
}
@media screen and (min-width: 1400px) {
  .verwacht-slider-template .nutezien-header h3 {
    font-size: 60px !important;
  }
}
.view-editview .block-editor-listing {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
