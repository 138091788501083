@media screen and (min-width: 768px) {
  .permanent-exhibitons-template {
    padding-top: 10px;
  }
}
@media screen and (min-width: 992px) {
  .permanent-exhibitons-template {
    padding-top: 13px;
  }
}
.permanent-exhibitons-template a.plone-item-card-link {
  text-decoration: none !important;
}
.permanent-exhibitons-template a.plone-item-card-link:hover .plone-item-title p {
  text-decoration: underline !important;
  text-decoration-thickness: 1px !important;
  -webkit-text-decoration-color: #575756;
          text-decoration-color: #575756;
  text-underline-offset: 5px !important;
}
.permanent-exhibitons-template .masonry-multiple-column {
  padding-left: 15px;
  padding-right: 15px;
}
@media screen and (min-width: 768px) {
  .permanent-exhibitons-template .masonry-multiple-column {
    padding: 0px 13px 40px 13px;
  }
}
@media screen and (min-width: 992px) {
  .permanent-exhibitons-template .masonry-multiple-column {
    padding: 0px 15px 40px 15px;
  }
}
.permanent-exhibitons-template .masonry-multiple-column .plone-item-card img {
  max-width: 100% !important;
  width: 100% !important;
}
.permanent-exhibitons-template .masonry-multiple-column > div {
  padding-bottom: 8px;
}
@media screen and (min-width: 992px) {
  .permanent-exhibitons-template .masonry-multiple-column > div {
    padding-bottom: 40px;
  }
}
.permanent-exhibitons-template .plone-item-title {
  text-decoration: none !important;
  border-bottom: 0px;
  margin-top: 15px;
  margin-bottom: 12.5px;
}
@media screen and (min-width: 992px) {
  .permanent-exhibitons-template .plone-item-title {
    margin-top: 10px;
    margin-bottom: 13px;
  }
}
.permanent-exhibitons-template .plone-item-title p {
  color: var(--bonnefanten-primary, #f9423a) !important;
  font-family: 'StyreneBold', Arial, sans-serif !important;
  font-size: 26px !important;
  line-height: 30px !important;
  border-bottom: none !important;
  margin-bottom: 10px;
}
.permanent-exhibitons-template .plone-item-title p:hover {
  border-bottom: 1px solid blue;
}
.permanent-exhibitons-template .plone-item-description {
  color: #b6a782 !important;
  font-size: 19px;
  margin-bottom: 0px;
}
@media screen and (max-width: 992px) {
  .permanent-exhibitons-template .plone-item-description {
    margin-bottom: 4px;
  }
}
.permanent-exhibitons-template .item-description span {
  margin-bottom: 5px;
  color: #575756;
  font-family: StyreneRegular, Arial, sans-serif;
  font-size: 17px !important;
  letter-spacing: 0 !important;
  line-height: 25px;
}
@media screen and (max-width: 992px) {
  .permanent-exhibitons-template .item-description span {
    font-size: 17px !important;
  }
}
.permanent-exhibitons-template .item-description {
  font-size: 17px !important;
}
@media screen and (max-width: 992px) {
  .permanent-exhibitons-template .item-description {
    font-size: 17px !important;
  }
}
.permanent-exhibitons-template .seperator {
  font-size: 8.5vw;
  line-height: 1.1;
  color: #b6a782 !important;
  font-family: 'BonnefantenBlock', Arial, sans-serif;
  text-align: left;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0em;
}
@media screen and (min-width: 768px) {
  .permanent-exhibitons-template .seperator {
    font-size: 4vw;
    line-height: 3.5vw;
    margin-top: 5px;
  }
}
.permanent-exhibitons-template .permanent-exhitions-header {
  margin-bottom: 22px !important;
  margin-left: 18px;
}
@media screen and (min-width: 768px) {
  .permanent-exhibitons-template .permanent-exhitions-header {
    margin-left: 14px;
  }
}
@media screen and (min-width: 992px) {
  .permanent-exhibitons-template .permanent-exhitions-header {
    margin-bottom: 70px !important;
    margin-left: 11px;
  }
}
@media screen and (min-width: 1200px) {
  .permanent-exhibitons-template .permanent-exhitions-header {
    margin-bottom: 36px;
  }
}
@media screen and (min-width: 1400px) {
  .permanent-exhibitons-template .permanent-exhitions-header {
    margin-bottom: 40px;
  }
}
.permanent-exhibitons-template .permanent-exhitions-header h3 {
  font-size: 11vw !important;
  line-height: 1.2 !important;
  margin-top: 0px !important;
  color: #b6a782 !important;
  font-family: 'BonnefantenBlock', Arial, sans-serif;
  text-align: left;
  margin-top: 25px;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0em;
}
@media screen and (min-width: 768px) {
  .permanent-exhibitons-template .permanent-exhitions-header h3 {
    margin-top: 8px !important;
    font-size: 8vw !important;
    line-height: 10vw !important;
  }
}
@media screen and (min-width: 992px) {
  .permanent-exhibitons-template .permanent-exhitions-header h3 {
    font-size: 8vw !important;
    line-height: 10vw !important;
  }
}
@media screen and (min-width: 1400px) {
  .permanent-exhibitons-template .permanent-exhitions-header h3 {
    font-size: 110px !important;
    line-height: 135px !important;
  }
}
.view-editview .block-editor-listing {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
