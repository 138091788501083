#advancedsearchblock #search-page {
  margin-top: -30px;
  width: auto !important;
}
@media screen and (min-width: 768px) {
  #advancedsearchblock #search-page {
    margin-left: 13.5px;
    margin-right: 13.5px;
    margin-top: 57px;
  }
}
@media screen and (min-width: 992px) {
  #advancedsearchblock #search-page {
    margin-left: 5%;
    margin-right: 5%;
    padding-right: 13px;
    padding-left: 13.5px;
    margin-top: 35px;
  }
}
#advancedsearchblock .searchbar {
  display: flex;
  flex-direction: column;
  width: auto;
}
@media screen and (min-width: 992px) {
  #advancedsearchblock .searchbar {
    flex-direction: row;
  }
}
#advancedsearchblock .text-input-facet {
  flex-grow: 1;
}
#advancedsearchblock .text-input-facet .ui.input {
  width: 100%;
  margin-bottom: 15px;
}
@media screen and (min-width: 992px) {
  #advancedsearchblock .text-input-facet .ui.input {
    margin-bottom: 0px;
  }
}
#advancedsearchblock .text-input-facet input {
  width: 100% !important;
  font-family: 'StyreneBold', Arial, sans-serif;
  font-size: 17px;
  padding: 16px 20px;
}
#advancedsearchblock .text-input-facet input::-moz-placeholder {
  font-size: 17px;
}
#advancedsearchblock .text-input-facet input::placeholder {
  font-size: 17px;
}
#advancedsearchblock .Search-main-button {
  border-radius: 0%;
  color: var(--bonnefanten-bg) !important;
  background-color: var(--bonnefanten-primary) !important;
  -webkit-clip-path: polygon(0% 10px, 10px 0%, calc(100%) 0%, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0 calc(100%));
          clip-path: polygon(0% 10px, 10px 0%, calc(100%) 0%, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0 calc(100%));
  padding: 16px 28px;
  border: 2px solid var(--bonnefanten-primary);
  font-family: 'StyreneBold', Arial, sans-serif;
  font-size: 17px;
  line-height: 1.4;
}
@media screen and (min-width: 992px) {
  #advancedsearchblock .Search-main-button {
    width: 19%;
    margin-left: 10px;
    padding: 12px 28px;
  }
}
@media screen and (min-width: 1300px) {
  #advancedsearchblock .Search-main-button {
    margin-left: 13px;
  }
}
#advancedsearchblock .Search-main-button:hover {
  background-color: var(--bonnefanten-bg) !important;
  color: var(--bonnefanten-primary) !important;
  -webkit-clip-path: none;
          clip-path: none;
}
#advancedsearchblock .react-select__indicator .icon path {
  fill: var(--bonnefanten-primary) !important;
}
#advancedsearchblock .react-select__menu {
  width: 99%;
}
#advancedsearchblock .link-to-search {
  margin-top: 25px;
  margin-bottom: 0px;
}
@media screen and (min-width: 992px) {
  #advancedsearchblock .link-to-search {
    margin-top: 2px;
  }
}
#advancedsearchblock .link-to-search a {
  border-bottom: 1px solid #b6a782;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 0em;
  color: #b6a782 !important;
  text-decoration: none !important;
}
