/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/* This is the single variable that controls them all*/
/* The size of page text*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*--------------
  Page Heading
---------------*/
/*-------------------
        Page
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
      SITE VARIABLES
--------------------*/
.view-viewview.contenttype-artwork .portal-top {
  height: 0px !important;
}
.view-viewview.contenttype-artwork .header-wrapper.contentpage {
  position: absolute;
}
.view-viewview.contenttype-artwork #object-block .react-swipe-container {
  padding-bottom: 55px;
}
@media screen and (max-width: 768px) {
  .view-viewview.contenttype-artwork #object-block .react-swipe-container {
    padding-bottom: 100px;
  }
}
.view-viewview.contenttype-artwork #object-block #swipe-slider .react-swipe-container {
  min-height: 400px;
}
.view-viewview.contenttype-artwork #object-block .buttons {
  padding-top: 23px;
  padding-bottom: 42px;
}
@media screen and (max-width: 768px) {
  .view-viewview.contenttype-artwork #object-block .buttons {
    padding-bottom: 68px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .view-viewview.contenttype-artwork #object-block .buttons {
    margin-top: 53px;
    padding-bottom: 39px;
  }
}
.view-viewview.contenttype-artwork #object-block .buttons .button {
  display: block;
  width: auto;
  height: auto;
  padding: 15.2px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 2px solid var(--bonnefanten-primary, #f9423a) !important;
  margin: 0 5.1px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-bottom: 5px;
  background: var(--bonnefanten-primary, #f9423a);
  border-radius: 0px;
  color: var(--bonnefanten-bg);
  float: right;
  font-family: 'StyreneBold', Arial, sans-serif;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 15px;
  text-shadow: 0px 0px 0px #ffffff;
  text-transform: none;
  transition: all 100ms linear;
  cursor: pointer;
}
.view-viewview.contenttype-artwork #object-block .buttons .button svg,
.view-viewview.contenttype-artwork #object-block .buttons .button path {
  width: 20px !important;
  height: 20px !important;
  stroke-width: 1px;
}
.view-viewview.contenttype-artwork #object-block .buttons .button.share {
  position: relative;
}
.view-viewview.contenttype-artwork #object-block .buttons .share-button {
  max-width: 68px;
}
.view-viewview.contenttype-artwork #object-block .buttons .button.expand {
  width: 200px;
  -webkit-clip-path: polygon(0% 10px, 10px 0%, calc(100%) 0%, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0 calc(100%));
          clip-path: polygon(0% 10px, 10px 0%, calc(100%) 0%, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0 calc(100%));
}
@media screen and (max-width: 768px) {
  .view-viewview.contenttype-artwork #object-block .buttons .button.expand {
    margin-left: -5px;
  }
}
@media screen and (max-width: 992px) {
  .view-viewview.contenttype-artwork #object-block .buttons .button.expand {
    width: 250px;
  }
}
.view-viewview.contenttype-artwork #object-block .buttons .button.zoomminus {
  margin-right: 15px;
  margin-left: 1px;
}
@media screen and (max-width: 768px) {
  .view-viewview.contenttype-artwork #object-block .buttons .button.zoomminus {
    margin-right: 5px;
  }
}
.view-viewview.contenttype-artwork #object-block .buttons .button.zoomplus {
  margin-right: 0px;
}
.view-viewview.contenttype-artwork #object-block .buttons .MagnifierPlus path {
  stroke-width: 30px;
}
.view-viewview.contenttype-artwork #object-block .buttons .button:hover {
  background: var(--bonnefanten-bg);
  -webkit-clip-path: none;
          clip-path: none;
  color: var(--bonnefanten-primary, #f9423a);
}
.view-viewview.contenttype-artwork #object-block .react-transform-component img {
  width: 100%;
  height: auto !important;
  margin: 0px !important;
  -o-object-fit: contain !important;
     object-fit: contain !important;
}
@media screen and (min-width: 992px) {
  .view-viewview.contenttype-artwork #object-block .react-transform-component img {
    padding-top: 12vw !important;
  }
}
@media screen and (max-width: 992px) {
  .view-viewview.contenttype-artwork #object-block .react-transform-component img {
    padding-top: 20vw !important;
  }
}
.view-viewview.contenttype-artwork #object-block .leftrightbuttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
.view-viewview.contenttype-artwork #object-block .leftrightbuttons button {
  border: 0px;
  background-color: transparent;
}
.view-viewview.contenttype-artwork #object-block .leftrightbuttons button svg,
.view-viewview.contenttype-artwork #object-block .leftrightbuttons button path {
  width: 38px !important;
  height: 23px !important;
  stroke-width: 0.1px;
}
.view-viewview.contenttype-artwork #object-block .leftrightbuttons .paginator {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
  padding-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
}
.view-viewview.contenttype-artwork #object-block .leftrightbuttons .paginator p {
  margin: 0%;
  font-size: 16px;
}
.view-viewview.contenttype-artwork #object-block .rawdata-section {
  overflow: hidden;
  max-height: 0;
  padding-right: 82px;
  padding-left: 82px;
  margin-top: 81px;
  margin-right: auto;
  margin-left: auto;
  opacity: 0;
  transition: max-height 0.5s ease-out, opacity 0.3s ease-out;
}
@media screen and (max-width: 1200px) {
  .view-viewview.contenttype-artwork #object-block .rawdata-section {
    padding-right: 73px;
    padding-left: 73px;
    margin-top: 42px;
  }
}
@media screen and (max-width: 992px) {
  .view-viewview.contenttype-artwork #object-block .rawdata-section {
    padding-right: 61px;
    padding-left: 61px;
    margin-top: 47px;
  }
}
@media screen and (max-width: 768px) {
  .view-viewview.contenttype-artwork #object-block .rawdata-section {
    padding-right: 42.5px;
    padding-left: 42.5px;
    margin-top: 14px;
  }
}
.view-viewview.contenttype-artwork #object-block .rawdata-section p {
  color: #9d9d9d;
  font-family: 'FranklinBook', Arial, sans-serif;
  font-size: 19.68px;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 36px;
}
.view-viewview.contenttype-artwork #object-block .rawdata-section a {
  border-bottom: 0px !important;
  color: #9d9d9d;
  text-decoration: underline;
}
.view-viewview.contenttype-artwork #object-block .rawdata-section a:hover {
  border-bottom: 0px !important;
  color: #000;
  text-decoration: none;
}
.view-viewview.contenttype-artwork #object-block .rawdata-section ul {
  padding-left: 20px;
  margin-top: 0px;
  margin-bottom: 10px;
}
.view-viewview.contenttype-artwork #object-block .rawdata-section li {
  padding-left: 0px;
}
.view-viewview.contenttype-artwork #object-block .rawdata-section li::marker {
  color: #9d9d9d;
}
.view-viewview.contenttype-artwork #object-block .rawdata-section.expanded {
  max-height: 30000px;
  padding-bottom: 214px !important;
  margin-top: 15px !important;
  opacity: 1;
}
@media screen and (max-width: 992px) {
  .view-viewview.contenttype-artwork #object-block .rawdata-section.expanded {
    padding-bottom: 141px !important;
  }
}
@media screen and (max-width: 768px) {
  .view-viewview.contenttype-artwork #object-block .rawdata-section.expanded {
    margin-top: 27px !important;
    padding-bottom: 92px !important;
  }
}
@media screen and (max-width: 992px) {
  .view-viewview.contenttype-artwork #object-block .rawdata-section.expanded .page-search-title {
    margin-bottom: 30px;
  }
}
.view-viewview.contenttype-artwork #object-block .zoom-container {
  display: flex;
  overflow: hidden;
  height: 80vh !important;
  height: 100%;
  min-height: 540px;
  align-items: center;
  justify-content: center;
  cursor: move;
}
@media screen and (max-width: 768px) {
  .view-viewview.contenttype-artwork #object-block .zoom-container {
    height: 56vh !important;
  }
}
.view-viewview.contenttype-artwork #object-block .buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}
.view-viewview.contenttype-artwork #object-block .social-media-popup {
  position: absolute;
  right: -20px;
  bottom: 62px;
  display: flex;
  width: 90px;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.view-viewview.contenttype-artwork #object-block .social-media-popup .popover-title {
  height: 20px;
  padding-bottom: 10px;
  margin-top: 0px;
  color: #ffffff;
  font-family: 'FranklinDmCp', Arial, sans-serif;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 30px;
}
.view-viewview.contenttype-artwork #object-block .social-media-popup .popover-content .row {
  margin-top: 5px;
}
.view-viewview.contenttype-artwork #object-block .social-media-popup a {
  border-bottom: none !important;
}
.view-viewview.contenttype-artwork #object-block .social-media-popup a:hover {
  border-bottom: none !important;
  color: #f8f80c;
}
.view-viewview.contenttype-artwork #object-block .social-media-popup .row {
  max-width: 70px;
}
.view-viewview.contenttype-artwork #object-block .social-media-popup .row.facebook-row img {
  height: 33px;
  max-height: 35px !important;
}
.view-viewview.contenttype-artwork #object-block .social-media-popup::after {
  position: absolute;
  bottom: -10px;
  left: 50%;
  /* Center the arrow and rotate it */
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-bottom: 10px solid black;
  border-left: 10px solid transparent;
  content: '';
  transform: translateX(-50%) rotate(180deg);
  /* This creates the arrow shape */
}
.contenttype-object .portal-top {
  height: 0px;
}
.contenttype-object .ui.basic.segment.content-area {
  padding-top: 0px;
  margin-top: 0px;
}
.contenttype-object #page-document.ui.container {
  width: calc(100vw - 20px) !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.contenttype-object #page-document.ui.container .documentFirstHeading {
  display: none;
}
@media (max-width: 768px) {
  .contenttype-object #page-document.ui.container > * {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
.view-viewview.contenttype-artwork #rawdata {
  width: auto;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 768px) {
  .view-viewview.contenttype-artwork #rawdata {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .view-viewview.contenttype-artwork #rawdata {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .view-viewview.contenttype-artwork #rawdata {
    width: 1170px;
  }
}
.view-viewview.contenttype-artwork #rawdata .data-wrapper {
  padding-right: 10px;
  padding-left: 10px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .view-viewview.contenttype-artwork #rawdata .data-wrapper {
    width: 90%;
  }
}
.view-viewview.contenttype-artwork #rawdata table {
  width: 100%;
}
.view-viewview.contenttype-artwork #rawdata table td p {
  margin: 0;
}
.view-viewview.contenttype-artwork #rawdata .description-wrapper {
  margin-bottom: 50px;
}
@media (min-width: 992px) {
  .view-viewview.contenttype-artwork #rawdata .description-wrapper {
    width: 720px;
    margin: 0 auto 38px;
  }
}
.view-viewview.contenttype-artwork #rawdata .expand-button {
  display: block;
  padding-left: 0px;
  border: 0px;
  background-color: #ffffff;
  cursor: pointer;
  font-family: 'FranklinBook', Arial, sans-serif;
  font-size: 19.68px;
  font-weight: 100;
  text-decoration: underline;
}
.view-viewview.contenttype-artwork #rawdata .expand-data-button {
  display: block;
  padding-left: 0px;
  border: 0px;
  margin-top: 10px;
  background-color: #ffffff;
  color: #9d9d9d;
  cursor: pointer;
  font-size: 19.68px;
  font-weight: 100;
  text-decoration: underline;
}
.view-viewview.contenttype-artwork #rawdata .expand-data-button:hover {
  color: #000;
}
.view-viewview.contenttype-artwork #rawdata #description.data-description {
  overflow: hidden;
  margin-bottom: 3px;
  color: #404040;
  font-size: 16px !important;
  line-height: 22.8px !important;
  transition: max-height 0.5s ease-out, opacity 0.3s ease-out;
}
.view-viewview.contenttype-artwork #rawdata #description.data-description.false {
  position: relative;
  max-height: 240px;
}
.view-viewview.contenttype-artwork #rawdata #description.data-description.true {
  position: relative;
  max-height: 1000px;
}
.view-viewview.contenttype-artwork #rawdata #description.data-description.false:after {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  content: '';
  opacity: 1;
  transition: opacity 0.6s;
}
@media screen and (max-width: 768px) {
  .view-viewview.contenttype-artwork #rawdata tr {
    display: flex;
    flex-direction: column;
  }
  .view-viewview.contenttype-artwork #rawdata tr .columntwo,
  .view-viewview.contenttype-artwork #rawdata tr .columnone {
    width: 100%;
    padding-left: 0px;
  }
  .view-viewview.contenttype-artwork #rawdata tr .columnone {
    padding-top: 0;
  }
  .view-viewview.contenttype-artwork #rawdata tr .columnone p {
    font-size: 20px !important;
  }
  .view-viewview.contenttype-artwork #rawdata tr .columntwo {
    padding-top: 0;
    padding-right: 10px;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  .view-viewview.contenttype-artwork #rawdata tr .columntwo p,
  .view-viewview.contenttype-artwork #rawdata tr .columntwo a {
    font-size: 16px !important;
  }
}
.view-viewview.contenttype-artwork #rawdata .columnone {
  width: 33.3%;
  padding: 15px 15px 0px 5px;
  vertical-align: top;
}
.view-viewview.contenttype-artwork #rawdata .columnone p {
  color: var(--bonnefanten-primary, #f9423a);
  font-family: 'StyreneBold', Arial, sans-serif;
  font-size: 18px !important;
  line-height: 25px;
}
.view-viewview.contenttype-artwork #rawdata .columntwo {
  width: 66.6%;
  padding: 15px 10px 0px 12px;
}
.view-viewview.contenttype-artwork #rawdata .columntwo p,
.view-viewview.contenttype-artwork #rawdata .columntwo a {
  color: #575756;
  font-family: 'StyreneRegular', Arial, Helvetica, sans-serif;
  font-size: 18px !important;
  letter-spacing: 0em;
  line-height: 25px;
  line-break: normal ;
}
.view-viewview.contenttype-artwork #rawdata table tr,
.view-viewview.contenttype-artwork #rawdata table.ui.table tr,
.view-viewview.contenttype-artwork #rawdata table.ui.table.block tr,
.view-viewview.contenttype-artwork #rawdata table th,
.view-viewview.contenttype-artwork #rawdata table.ui.table th,
.view-viewview.contenttype-artwork #rawdata table.ui.table.block th,
.view-viewview.contenttype-artwork #rawdata table td,
.view-viewview.contenttype-artwork #rawdata table.ui.table td,
.view-viewview.contenttype-artwork #rawdata table.ui.table.block td {
  border: 0;
}
.react-transform-wrapper,
.react-transform-component,
.react-transform-component img {
  max-height: 80vh !important;
}
@media screen and (max-width: 768px) {
  .react-transform-wrapper,
  .react-transform-component,
  .react-transform-component img {
    max-height: 55vh !important;
  }
}
.react-transform-wrapper {
  width: 100% !important;
}
.react-transform-component {
  width: auto !important;
  height: 100% !important;
}
.view-viewview.contenttype-artwork #page-search-artwork {
  max-width: 1250px !important;
  padding-top: 19px;
  padding-right: 84px;
  padding-left: 85px;
}
@media screen and (max-width: 1200px) {
  .view-viewview.contenttype-artwork #page-search-artwork {
    padding-top: 60px;
    padding-right: 50px;
    padding-left: 50px;
  }
}
@media screen and (max-width: 992px) {
  .view-viewview.contenttype-artwork #page-search-artwork {
    width: auto !important;
    padding-top: 12px;
    padding-right: 27px;
    padding-left: 27px;
  }
}
@media screen and (max-width: 768px) {
  .view-viewview.contenttype-artwork #page-search-artwork {
    padding-top: 0px;
    padding-right: 12px !important;
    padding-left: 11.5px !important;
  }
}
.view-viewview.contenttype-artwork #page-search-artwork .page-search-title {
  margin-bottom: 63px;
  text-align: center;
}
@media screen and (max-width: 992px) {
  .view-viewview.contenttype-artwork #page-search-artwork .page-search-title {
    margin-bottom: 38px;
  }
}
@media screen and (max-width: 768px) {
  .view-viewview.contenttype-artwork #page-search-artwork .page-search-title {
    margin-bottom: 31px;
  }
}
.view-viewview.contenttype-artwork #page-search-artwork .page-search-title h1 {
  margin-top: 10px;
  color: #b6a782;
  font-size: 4vw;
  letter-spacing: 0em;
}
@media screen and (max-width: 992px) {
  .view-viewview.contenttype-artwork #page-search-artwork .page-search-title h1 {
    font-size: 8vw;
  }
}
@media screen and (max-width: 768px) {
  .view-viewview.contenttype-artwork #page-search-artwork .page-search-title h1 {
    font-size: 6vw;
  }
}
.view-viewview.contenttype-artwork #page-search-artwork .SeeMoreItem {
  height: auto;
  box-sizing: border-box;
  padding: 20px 20px 20px 20px;
  -moz-column-break-inside: avoid;
       break-inside: avoid;
}
.view-viewview.contenttype-artwork #page-search-artwork .SeeMoreItem a {
  font-family: 'StyreneBold', Arial, sans-serif;
  text-decoration: none;
}
.view-viewview.contenttype-artwork #page-search-artwork .SeeMoreItem img {
  width: 100% !important;
  margin-bottom: 12px !important;
}
@media screen and (min-width: 768px) {
  .view-viewview.contenttype-artwork #page-search-artwork .SeeMoreItem img {
    margin-bottom: 8px !important;
  }
}
@media screen and (min-width: 1200px) {
  .view-viewview.contenttype-artwork #page-search-artwork .SeeMoreItem img {
    margin-bottom: 8px !important;
  }
}
.view-viewview.contenttype-artwork #page-search-artwork .SeeMoreItem .description {
  padding-top: 9px;
}
.view-viewview.contenttype-artwork #page-search-artwork .SeeMoreItem .description p {
  margin-bottom: 5px;
}
@media screen and (max-width: 992px) {
  .view-viewview.contenttype-artwork #page-search-artwork .SeeMoreItem .description {
    padding-top: 9px;
  }
  .view-viewview.contenttype-artwork #page-search-artwork .SeeMoreItem .description p {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 768px) {
  .view-viewview.contenttype-artwork #page-search-artwork .SeeMoreItem .description {
    padding-top: 9px;
  }
  .view-viewview.contenttype-artwork #page-search-artwork .SeeMoreItem .description p {
    margin-bottom: 0px !important;
  }
}
.view-viewview.contenttype-artwork #page-search-artwork .SeeMoreItem .item-description {
  margin-bottom: 5px;
  color: #575756;
  font-family: 'StyreneRegular', Arial, sans-serif;
  font-size: 19px;
  letter-spacing: 0px;
  line-height: 25px;
  font-weight: 500;
}
@media screen and (min-width: 1200px) {
  .view-viewview.contenttype-artwork #page-search-artwork .SeeMoreItem .item-description {
    margin-left: -0.4px;
  }
}
.view-viewview.contenttype-artwork #page-search-artwork .item_title {
  color: var(--bonnefanten-primary, #f9423a);
  font-family: 'StyreneBold', Arial, sans-serif;
  font-size: 26px;
  text-decoration: none;
  line-height: 30px;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: 1px solid var(--bonnefanten-bg);
}
@media screen and (min-width: 1200px) {
  .view-viewview.contenttype-artwork #page-search-artwork .item_title {
    margin-left: -0.4px;
  }
}
.view-viewview.contenttype-artwork #page-search-artwork .item_title:hover {
  border-bottom: 1px solid var(--bonnefanten-primary);
}
