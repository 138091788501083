@media screen and (min-width: 768px) {
  .section-advancedsearch .documentFirstHeading {
    padding-left: 20px !important;
    margin-top: 58px !important;
  }
}
@media screen and (min-width: 992px) {
  .section-advancedsearch .documentFirstHeading {
    padding-left: 21px !important;
    margin-top: 118px !important;
    margin-bottom: 37px;
  }
}
.section-advancedsearch #view {
  margin-top: 3px;
}
@media screen and (min-width: 768px) {
  .section-advancedsearch #view {
    margin-right: 20px;
    margin-left: 20px;
  }
}
@media screen and (min-width: 992px) {
  .section-advancedsearch #view {
    margin-right: 0px;
    margin-left: 0px;
  }
}
.section-advancedsearch #page-document {
  width: auto !important;
}
.section-advancedsearch #page-document .content-container {
  width: auto !important;
  padding-top: 14px;
  padding-right: 10%;
  padding-left: 10%;
  margin-top: 0px;
}
@media screen and (min-width: 768px) {
  .section-advancedsearch #page-document .content-container {
    padding-top: 9px;
    padding-right: 7.5%;
    padding-left: 7.5%;
  }
}
@media screen and (min-width: 1400px) {
  .section-advancedsearch #page-document .content-container {
    max-width: 1300px;
    padding-top: 7px;
  }
}
.section-advancedsearch #page-document .content-container .column {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.section-advancedsearch .block.search {
  padding-right: 3px;
  padding-left: 3px;
}
.section-advancedsearch .block.search .searchBlock-facets {
  max-width: 100% !important;
  margin-right: 0;
  margin-left: 0;
}
@media screen and (min-width: 768px) {
  .section-advancedsearch .block.search {
    padding-right: 9px;
    padding-left: 9px;
  }
}
@media screen and (min-width: 992px) {
  .section-advancedsearch .block.search {
    padding-right: 13px;
    padding-left: 13px;
  }
}
@media screen and (min-width: 1400px) {
  .section-advancedsearch .block.search {
    padding-right: 7.2px;
    padding-left: 7.2px;
  }
}
.section-advancedsearch .block.search .search-input input {
  height: 58px;
}
.section-advancedsearch .block.search .search-wrapper {
  padding-bottom: 41px;
}
@media screen and (min-width: 768px) {
  .section-advancedsearch .block.search .search-wrapper {
    padding-bottom: 41px;
  }
}
@media screen and (min-width: 992px) {
  .section-advancedsearch .block.search .search-wrapper {
    padding-bottom: 32px;
  }
}
.section-advancedsearch .block.search .text-input-facet,
.section-advancedsearch .block.search .select-facet {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between !important;
  margin-top: 15px;
}
@media screen and (min-width: 768px) {
  .section-advancedsearch .block.search .text-input-facet,
  .section-advancedsearch .block.search .select-facet {
    flex-direction: row;
    padding-right: 0px;
    margin-top: 15px;
  }
}
.section-advancedsearch .block.search .ui.header {
  display: block !important;
  padding-bottom: 6px;
  margin-top: 18px !important;
  margin-bottom: auto !important;
  color: #b6a782;
  font-family: 'StyreneRegular', 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
}
@media screen and (min-width: 768px) {
  .section-advancedsearch .block.search .ui.header {
    width: 33%;
    padding-bottom: 0px;
  }
}
.section-advancedsearch .block.search .ui.input > input,
.section-advancedsearch .block.search .react-select-container > input {
  padding-top: 15.8px !important;
  padding-bottom: 15.8px !important;
}
@media screen and (min-width: 768px) {
  .section-advancedsearch .block.search .text-input-facet .ui.input,
  .section-advancedsearch .block.search .react-select-container {
    padding-left: 10px;
  }
}
.section-advancedsearch .block.search .ui.input,
.section-advancedsearch .block.search .react-select-container {
  width: 100%;
}
.section-advancedsearch .block.search .ui.input input,
.section-advancedsearch .block.search .react-select-container input,
.section-advancedsearch .block.search .ui.input .react-select__value-container,
.section-advancedsearch .block.search .react-select-container .react-select__value-container {
  background-color: var(--bonnefanten-bg) !important;
  color: var(--bonnefanten-primary) !important;
  font-family: 'StyreneBold', Arial, sans-serif !important;
  font-size: 17px !important;
  font-weight: 400;
  letter-spacing: 0em;
}
.section-advancedsearch .block.search .ui.input input::-moz-placeholder, .section-advancedsearch .block.search .react-select-container input::-moz-placeholder, .section-advancedsearch .block.search .ui.input .react-select__placeholder::-moz-placeholder, .section-advancedsearch .block.search .react-select-container .react-select__placeholder::-moz-placeholder {
  color: var(--bonnefanten-primary) !important;
  font-family: 'StyreneBold', Arial, sans-serif !important;
  font-size: 17px !important;
  font-weight: 400;
  letter-spacing: 0em;
}
.section-advancedsearch .block.search .ui.input input::placeholder,
.section-advancedsearch .block.search .react-select-container input::placeholder,
.section-advancedsearch .block.search .ui.input .react-select__placeholder::placeholder,
.section-advancedsearch .block.search .react-select-container .react-select__placeholder::placeholder {
  color: var(--bonnefanten-primary) !important;
  font-family: 'StyreneBold', Arial, sans-serif !important;
  font-size: 17px !important;
  font-weight: 400;
  letter-spacing: 0em;
}
.section-advancedsearch .block.search .react-select__value-container,
.section-advancedsearch .block.search .react-select__control,
.section-advancedsearch .block.search .react-select-container {
  background-color: var(--bonnefanten-bg) !important;
  color: var(--bonnefanten-primary) !important;
}
.section-advancedsearch .block.search .react-select__control,
.section-advancedsearch .block.search .react-select__control:hover {
  padding: 10px 31px;
  border: 2px solid var(--bonnefanten-primary);
}
@media screen and (min-width: 768px) {
}
.section-advancedsearch .block.search .react-select__placeholder {
  color: var(--bonnefanten-primary) !important;
}
.section-advancedsearch .block.search .react-select__multi-value {
  flex-direction: row-reverse;
  background-color: var(--bonnefanten-primary);
  border-radius: 3px;
  color: var(--bonnefanten-bg) !important;
}
.section-advancedsearch .block.search .react-select__multi-value .react-select__multi-value__label {
  padding-right: 6px;
  padding-left: 0px;
  color: var(--bonnefanten-bg) !important;
  font-family: 'StyreneBold', Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0em;
  line-height: 20px;
}
.section-advancedsearch .block.search .react-select__multi-value .react-select__multi-value__remove {
  padding-right: 2px;
}
.section-advancedsearch .block.search .react-select__multi-value .react-select__multi-value__remove svg {
  width: 16px;
  height: 16px;
  stroke-width: 1.5px;
}
.section-advancedsearch .block.search .Search-main-button {
  padding: 16px 29px;
  border: 2px solid var(--bonnefanten-primary);
  margin-top: 41px;
  margin-left: 0px;
  background-color: var(--bonnefanten-primary) !important;
  border-radius: 0%;
  -webkit-clip-path: polygon(0% 10px, 10px 0%, calc(100%) 0%, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0 calc(100%));
          clip-path: polygon(0% 10px, 10px 0%, calc(100%) 0%, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0 calc(100%));
  color: var(--bonnefanten-bg) !important;
  font-family: 'StyreneBold', Arial, sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.4;
}
@media screen and (min-width: 768px) {
  .section-advancedsearch .block.search .Search-main-button {
    margin-top: 24px;
  }
}
.section-advancedsearch .block.search .Search-main-button:hover {
  background-color: var(--bonnefanten-bg) !important;
  -webkit-clip-path: none;
          clip-path: none;
  color: var(--bonnefanten-primary) !important;
}
.section-advancedsearch .block.search .react-select__indicator .icon path {
  fill: var(--bonnefanten-primary) !important;
}
.section-advancedsearch .block.search .react-select__menu {
  width: 99%;
  border: 2px solid var(--bonnefanten-primary);
  background-color: var(--bonnefanten-bg) !important;
}
.section-advancedsearch .block.search .react-select__menu .react-select__option {
  padding-top: 0;
  padding-bottom: 0;
  color: black;
  font-size: 12px;
  line-height: 12px;
}
.section-advancedsearch .block.search .react-select__menu .react-select__option:hover {
  background-color: var(--bonnefanten-primary);
}
.section-advancedsearch .listing-template .ui.grid .plone-item-card {
  display: flex;
  flex-direction: column;
  padding: 15px !important;
  margin-top: auto;
}
.section-advancedsearch .listing-template .ui.grid .plone-item-title {
  margin-top: auto;
}
