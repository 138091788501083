#vimeo-block {
  position: relative;
  width: 100vw;
  overflow: hidden;
  right: 50%;
  left: 50%;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
@media (min-width: 1640px) {
}
#vimeo-block .slider-caption {
  position: absolute;
  bottom: 0px;
  pointer-events: all;
  z-index: 2;
  margin-bottom: 0px;
  padding-top: 50px;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 70%);
  background-repeat: repeat-x;
  width: 100%;
}
@media screen and (min-width: 1200px) {
  #vimeo-block .slider-caption {
    margin-left: -0.8px;
  }
}
#vimeo-block .slide-body {
  padding-left: 0px;
  padding-right: 0px;
}
#vimeo-block .hero-dates-wrapper {
  color: #b6a782 !important;
  text-transform: uppercase !important;
  margin-bottom: 10px !important;
  line-height: 25px !important;
  letter-spacing: 0em !important;
  font-size: 25px !important;
  font-family: 'StyreneRegular', Arial, sans-serif !important;
}
@media screen and (max-width: 1200px) {
  #vimeo-block .hero-dates-wrapper .hero-dates span {
    font-size: 20px !important;
  }
}
#vimeo-block .slide-title {
  text-align: left !important;
  font-size: 28px !important;
  line-height: 32px !important;
  color: #b6a782 !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  letter-spacing: 0em !important;
  font-weight: 400 !important;
  text-transform: none !important;
  font-family: 'StyreneBold', Arial, sans-serif !important;
}
@media screen and (min-width: 768px) {
  #vimeo-block .slide-title {
    font-size: 30px !important;
    line-height: 34px !important;
  }
}
@media screen and (min-width: 1200px) {
  #vimeo-block .slide-title {
    font-size: 38px !important;
    line-height: 45px !important;
  }
}
#vimeo-block .description-wrapper {
  width: 100%;
  padding: 5px 120px 25px 120px !important;
}
#vimeo-block .description-wrapper .quote-bonnefanten {
  top: 10px !important;
}
@media screen and (max-width: 1200px) {
  #vimeo-block .description-wrapper {
    padding: 0 27.5px 25px !important;
  }
}
#vimeo-block .first-frame img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#vimeo-block .video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 Aspect Ratio */
  height: 0;
}
#vimeo-block iframe {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.view-editview #vimeo-block iframe {
  z-index: -1;
}
#vimeo-block .vimeo-buttons {
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 20px;
  padding-top: 25px;
  padding-bottom: 40px;
}
@media (min-width: 768px) {
  #vimeo-block .vimeo-buttons {
    position: absolute;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
#vimeo-block .vimeo-buttons a.button {
  padding-top: 17px;
  padding-bottom: 12px;
  margin: 10px 20px !important;
  text-transform: uppercase;
  color: #282931;
  width: 228px;
  font-family: 'FranklinMed', Arial, sans-serif;
  letter-spacing: 0.05em;
  font-size: 17px;
  text-align: center;
  margin-left: 38px !important;
  margin-right: 38px !important;
  border: 1px solid #282931 !important;
}
#vimeo-block .vimeo-buttons a.button:hover {
  background-color: #282931 !important;
  border: 1px solid #282931 !important;
  color: #fff;
}
@media (min-width: 768px) {
  #vimeo-block .vimeo-buttons a.button {
    color: #fff;
  }
  #vimeo-block .vimeo-buttons a.button:hover {
    background-color: #282931 !important;
    border: 1px solid #282931 !important;
  }
  #vimeo-block .vimeo-buttons a.button1 {
    background: transparent;
    border: 1px solid #fff !important;
  }
  #vimeo-block .vimeo-buttons a.button2 {
    background: transparent;
    color: #ffffff;
    border: 1px solid #ffffff !important;
  }
}
@media screen and (max-width: 600px) {
  #vimeo-block .vimeo-buttons {
    flex-direction: column !important;
    align-items: center;
    bottom: 270px;
  }
}
@media screen and (max-width: 480px) {
  #vimeo-block .vimeo-buttons {
    bottom: 180px;
  }
}
