.tedoenview {
  padding-right: 1rem;
  padding-left: 1rem;
}
.tedoenview a:hover {
  color: var(--bonnefanten-primary-text, #575756) !important;
}
.tedoenview .listing-template .plone-item-card .listing-dates-wrapper {
  padding-top: 9px;
  padding-bottom: 9px;
}
.tedoenview .listing-template .plone-item-card .hero-dates {
  color: #b6a782;
  font-size: 17px;
}
.tedoenview .listing-template .plone-item-card .expired .hero-dates {
  text-decoration: line-through;
}
.tedoenview .listing-template .plone-item-card .plone-item-description {
  color: #b6a782 !important;
}
.tedoenview .listing-template .plone-item-card .plone-item-descriptionspan {
  color: #b6a782 !important;
}
@media screen and (max-width: 768px) {
  .tedoenview .multiple-content-view .listing-template .column.listings .listing-column {
    padding-bottom: 0.35rem !important;
  }
}
.tedoenview .permanent-exhitions-header {
  margin-bottom: 22px !important;
  margin-left: 18px;
}
@media screen and (min-width: 768px) {
  .tedoenview .permanent-exhitions-header {
    margin-left: 14px;
  }
}
@media screen and (min-width: 992px) {
  .tedoenview .permanent-exhitions-header {
    margin-bottom: 70px !important;
    margin-left: 11px;
  }
}
@media screen and (min-width: 1200px) {
  .tedoenview .permanent-exhitions-header {
    margin-bottom: 36px;
  }
}
@media screen and (min-width: 1400px) {
  .tedoenview .permanent-exhitions-header {
    margin-bottom: 40px;
  }
}
.tedoenview .permanent-exhitions-header h3 {
  font-size: 11vw !important;
  line-height: 1.2 !important;
  margin-top: 0px !important;
  color: #b6a782 !important;
  font-family: 'BonnefantenBlock', Arial, sans-serif;
  text-align: left;
  margin-top: 25px;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0em;
}
@media screen and (min-width: 768px) {
  .tedoenview .permanent-exhitions-header h3 {
    margin-top: 8px !important;
    font-size: 8vw !important;
    line-height: 10vw !important;
  }
}
@media screen and (min-width: 992px) {
  .tedoenview .permanent-exhitions-header h3 {
    font-size: 8vw !important;
    line-height: 10vw !important;
  }
}
@media screen and (min-width: 1400px) {
  .tedoenview .permanent-exhitions-header h3 {
    font-size: 110px !important;
    line-height: 135px !important;
  }
}
.view-editview .block-editor-listing {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
