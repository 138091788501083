#view #quote-block-wrapper.VideoPageBlock,
#page-edit #quote-block-wrapper.VideoPageBlock,
#view .block-editor-VideoPageBlock,
#page-edit .block-editor-VideoPageBlock {
  position: relative;
  width: 100vw;
  right: 50% !important;
  left: 50% !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
  padding: 0%;
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.VideoPageBlock,
  #page-edit #quote-block-wrapper.VideoPageBlock,
  #view .block-editor-VideoPageBlock,
  #page-edit .block-editor-VideoPageBlock {
    margin-top: calc(5vw) !important;
    margin-bottom: calc(5vw) !important;
  }
}
#view #quote-block-wrapper.VideoPageBlock .block.align.center iframe,
#page-edit #quote-block-wrapper.VideoPageBlock .block.align.center iframe,
#view .block-editor-VideoPageBlock .block.align.center iframe,
#page-edit .block-editor-VideoPageBlock .block.align.center iframe {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  transform: none;
}
#view #quote-block-wrapper.VideoPageBlock .block.VideoPageBlock::before,
#page-edit #quote-block-wrapper.VideoPageBlock .block.VideoPageBlock::before,
#view .block-editor-VideoPageBlock .block.VideoPageBlock::before,
#page-edit .block-editor-VideoPageBlock .block.VideoPageBlock::before {
  z-index: 0;
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid {
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 23px;
  margin: 0% !important;
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid {
    flex-direction: row;
    padding-bottom: calc(5.21vw + 10px);
  }
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid {
    padding-top: calc(5.21vw - 18px);
    padding-left: calc(5vw + 8px);
    padding-right: calc(5vw);
    padding-bottom: calc(5.21vw + 20px);
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid {
    padding-top: calc(5.21vw - 15px);
    padding-left: calc(5vw + 7px);
    padding-bottom: calc(5.21vw + 30px);
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
@media (min-width: 1600px) {
}
@media (min-width: 2200px) {
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video {
  width: 100%;
  flex-basis: 100%;
  padding: 0% !important;
}
@media (max-width: 992px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video {
    padding-bottom: 5px !important;
  }
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video .block.video.align,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video .block.video.align,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video .block.video.align,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video .block.video.align {
  margin-bottom: 0px;
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video .video-inner,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video .video-inner,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video .video-inner,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-video .video-inner {
  padding-top: 0px !important;
  padding-right: 0px !important;
  margin: 0% !important;
  width: calc(100% - 8px);
  transform: none !important;
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
  line-height: unset !important;
  flex-basis: 100%;
  width: 100%;
  display: block !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  align-self: flex-start;
}
@media (min-width: 550px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-left: 33px !important;
    padding-right: 33px !important;
  }
}
@media (min-width: 650px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-left: 41px !important;
    padding-right: 43px !important;
  }
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-right: calc(6% - 3px) !important;
    padding-left: calc(6% - 3px) !important;
  }
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    width: 50%;
    flex-basis: 50%;
    padding-top: 4% !important;
    padding-right: 0% !important;
    padding-left: 0% !important;
    margin-left: -4px;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-top: 1% !important;
    margin-left: -7px;
  }
}
@media (min-width: 1400px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-top: 1% !important;
  }
}
@media (min-width: 1600px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-top: 2% !important;
  }
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text.right,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text.right,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text.right,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text.right {
  align-self: flex-end;
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2 {
  padding: 0% !important;
  color: var(--bonnefanten-primary);
  margin-top: 10px !important;
  margin-bottom: 15px !important;
  margin-left: 0px !important;
  font-family: 'StyreneBold', Arial, sans-serif;
  letter-spacing: 0em;
  text-transform: none;
  font-size: 1.25em !important;
  line-height: 30px !important;
  text-align: center;
}
@media (min-width: 650px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2 {
    padding-top: 2px !important;
  }
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2 {
    font-size: 2.6vw !important;
    margin-top: 8px !important;
    padding-top: 0px !important;
    margin-bottom: 15px !important;
    margin-left: 0px !important;
    line-height: 1.45em !important;
  }
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2 {
    margin-top: 0px !important;
    margin-bottom: 35px !important;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid h2 {
    font-size: 2.8vw !important;
    line-height: 1.45em !important;
    text-align: left;
    margin-top: 1px !important;
  }
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
  padding: 0% !important;
  font-size: 1.25em !important;
  line-height: 30px !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  font-family: 'StyreneRegular', Arial, sans-serif;
  letter-spacing: 0em;
  color: #b6a782;
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image) a,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image) a,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image) a,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image) a,
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer a,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer a,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer a,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer a {
  text-decoration-thickness: 1px !important;
  text-underline-offset: 6px;
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image) a:hover,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image) a:hover,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image) a:hover,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image) a:hover,
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer a:hover,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer a:hover,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer a:hover,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer a:hover {
  text-decoration: underline !important;
  text-decoration-thickness: 1px !important;
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
    font-size: 2vw !important;
    line-height: 1.45em !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
    font-size: 2vw !important;
    line-height: 1.45em !important;
    margin-top: 8px !important;
    margin-bottom: 0px !important;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p:not(.image),
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
    font-size: 1.8vw !important;
  }
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p em,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid p em,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p em,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid p em {
  font-style: italic;
}
body.page-title-in-view #view #quote-block-wrapper.VideoPageBlock .grid-block-text h2,
body.page-title-in-view #page-edit #quote-block-wrapper.VideoPageBlock .grid-block-text h2,
body.page-title-in-view #view .block-editor-VideoPageBlock .grid-block-text h2,
body.page-title-in-view #page-edit .block-editor-VideoPageBlock .grid-block-text h2 {
  color: var(--bonnefanten-primary) !important;
}
body.page-title-out-of-view #view #quote-block-wrapper.VideoPageBlock .grid-block-text h2,
body.page-title-out-of-view #page-edit #quote-block-wrapper.VideoPageBlock .grid-block-text h2,
body.page-title-out-of-view #view .block-editor-VideoPageBlock .grid-block-text h2,
body.page-title-out-of-view #page-edit .block-editor-VideoPageBlock .grid-block-text h2,
body.page-title-out-of-view #view #quote-block-wrapper.VideoPageBlock p,
body.page-title-out-of-view #page-edit #quote-block-wrapper.VideoPageBlock p,
body.page-title-out-of-view #view .block-editor-VideoPageBlock p,
body.page-title-out-of-view #page-edit .block-editor-VideoPageBlock p {
  color: #575756 !important;
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty),
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty),
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty),
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) {
  padding-top: 50px !important;
}
@media screen and (min-width: 768px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty),
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty),
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty),
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) {
    padding-top: 53px !important;
  }
}
@media screen and (min-width: 992px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty),
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty),
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty),
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) {
    padding-top: 96px !important;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty),
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty),
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty),
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) {
    padding-top: calc(6vw + 34px) !important;
    padding-bottom: calc(5vw - 2px) !important;
  }
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) p::after,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) p::after,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) p::after,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) p::after {
  position: absolute;
  top: 0;
  left: calc(50% - 15px);
  content: "\002F \002F";
  font-family: 'BonnefantenBlock', Arial, sans-serif;
  font-size: 6vw;
  color: #b6a782;
}
@media screen and (min-width: 992px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) p::after,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) p::after,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) p::after,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) p::after {
    left: 0%;
    margin-top: calc(7vw + 28px);
    margin-left: -8vw;
  }
}
@media screen and (min-width: 1200px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) p::after,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) p::after,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) p::after,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) p::after {
    margin-top: calc(7vw + 36px);
  }
}
@media screen and (min-width: 1400px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) p::after,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) p::after,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) p::after,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty) p::after {
    margin-top: calc(7vw + 45px);
  }
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty):has(br)::after,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty):has(br)::after,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty):has(br)::after,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3):not(.empty):has(br)::after {
  content: "";
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty),
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty),
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty),
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) {
  padding-top: 50px !important;
}
@media screen and (min-width: 768px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty),
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty),
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty),
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) {
    padding-top: 53px !important;
  }
}
@media screen and (min-width: 992px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty),
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty),
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty),
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) {
    padding-top: 96px !important;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty),
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty),
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty),
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) {
    padding-top: calc(6vw + 34px) !important;
    padding-bottom: calc(5vw - 2px) !important;
  }
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) p::after,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) p::after,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) p::after,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) p::after {
  position: absolute;
  top: 0;
  right: 50%;
  content: "\002F \002F";
  font-family: 'BonnefantenBlock', Arial, sans-serif;
  font-size: 6vw;
  color: #b6a782;
}
@media screen and (min-width: 992px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) p::after,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) p::after,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) p::after,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) p::after {
    right: 0%;
    margin-top: calc(7vw + 28px);
    margin-right: -3.5vw;
  }
}
@media screen and (min-width: 1200px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) p::after,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) p::after,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) p::after,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) p::after {
    margin-top: calc(7vw + 36px);
  }
}
@media screen and (min-width: 1400px) {
  #view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) p::after,
  #page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) p::after,
  #view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) p::after,
  #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty) p::after {
    margin-top: calc(7vw + 45px);
  }
}
#view #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty):has(br)::after,
#page-edit #quote-block-wrapper.VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty):has(br)::after,
#view .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty):has(br)::after,
#page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2):not(.empty):has(br)::after {
  content: "";
}
#page-edit .block-editor-VideoPageBlock {
  width: 90vw !important;
  padding-left: 100px !important;
}
#page-edit .block-editor-VideoPageBlock .block-add-button,
#page-edit .block-editor-VideoPageBlock .remove-block-button,
#page-edit .block-editor-VideoPageBlock .toolbar {
  display: none;
}
.has-sidebar #page-edit .block-editor-VideoPageBlock {
  padding-left: 150px !important;
  width: 70vw !important;
  margin-right: -40vw !important;
  margin-left: -40vw !important;
}
@media screen and (max-width: 1150px) {
  .has-sidebar #page-edit .block-editor-VideoPageBlock {
    width: 65vw !important;
  }
  .has-sidebar #page-edit .block-editor-VideoPageBlock .ui.stackable.stretched.column.grid {
    padding-left: 0px !important;
  }
}
@media screen and (max-width: 1100px) {
  .has-sidebar #page-edit .block-editor-VideoPageBlock {
    padding-left: 120px !important;
    width: 60vw !important;
  }
}
@media screen and (max-width: 1000px) {
  .has-sidebar #page-edit .block-editor-VideoPageBlock {
    padding-left: 50px !important;
  }
}
@media screen and (max-width: 768px) {
  .has-sidebar #page-edit .block-editor-VideoPageBlock {
    padding-left: 0px !important;
  }
}
