#view #quote-block-wrapper.ImageAndTextBlock,
#page-edit #quote-block-wrapper.ImageAndTextBlock,
#view .block-editor-ImageAndTextBlock,
#page-edit .block-editor-ImageAndTextBlock {
  position: relative;
  width: 100vw;
  right: 50% !important;
  left: 50% !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
  padding: 0%;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.ImageAndTextBlock,
  #page-edit #quote-block-wrapper.ImageAndTextBlock,
  #view .block-editor-ImageAndTextBlock,
  #page-edit .block-editor-ImageAndTextBlock {
    margin-top: calc(5.21vw + 2px) !important;
    margin-bottom: calc(5.21vw - 6px) !important;
    padding-bottom: 5.21vw;
  }
}
#view #quote-block-wrapper.ImageAndTextBlock .text-button.btn-block.primary,
#page-edit #quote-block-wrapper.ImageAndTextBlock .text-button.btn-block.primary,
#view .block-editor-ImageAndTextBlock .text-button.btn-block.primary,
#page-edit .block-editor-ImageAndTextBlock .text-button.btn-block.primary {
  line-height: 2.2rem;
}
#view #quote-block-wrapper.ImageAndTextBlock .text-button.btn-block:hover,
#page-edit #quote-block-wrapper.ImageAndTextBlock .text-button.btn-block:hover,
#view .block-editor-ImageAndTextBlock .text-button.btn-block:hover,
#page-edit .block-editor-ImageAndTextBlock .text-button.btn-block:hover {
  background-color: #B6A782 !important;
  border-color: #B6A782 !important;
}
#view #quote-block-wrapper.ImageAndTextBlock .block.ImageAndTextBlock::before,
#page-edit #quote-block-wrapper.ImageAndTextBlock .block.ImageAndTextBlock::before,
#view .block-editor-ImageAndTextBlock .block.ImageAndTextBlock::before,
#page-edit .block-editor-ImageAndTextBlock .block.ImageAndTextBlock::before {
  z-index: 0;
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid {
  justify-content: center;
  padding-bottom: 23px;
  margin: 0% !important;
  position: relative;
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid {
    flex-direction: row;
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    padding-left: calc(5vw + 1px);
    padding-right: calc(5vw + 2px);
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    align-items: start;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .block.image.align.center,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .block.image.align.center,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .block.image.align.center,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .block.image.align.center {
    padding-top: 0px !important;
  }
}
@media (min-width: 1600px) {
}
@media (min-width: 2200px) {
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
  width: 100% !important;
  height: auto;
  padding: 0% !important;
}
@media (max-width: 768px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
    order: -1;
  }
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image p.block.image.align,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image p.block.image.align,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image p.block.image.align,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image p.block.image.align,
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image .image-wrapper-,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image .image-wrapper-,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image .image-wrapper-,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image .image-wrapper- {
  margin: 0px;
  padding: 0px !important;
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image img,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image img,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image img,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image img {
  max-width: 100%;
  margin: 0%;
  transform: none;
  width: 100%;
  height: auto;
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span,
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p {
  color: var(--bonnefanten-primary-text);
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 10px;
  text-align: left !important;
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span sub,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span sub,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span sub,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span sub,
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p sub,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p sub,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p sub,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p sub {
  color: #282931;
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span a,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span a,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span a,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span a,
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p a,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p a,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p a,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p a {
  color: #282931;
  border-bottom: 1px solid #282931 !important;
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span a:hover,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span a:hover,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span a:hover,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit span a:hover,
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p a:hover,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p a:hover,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p a:hover,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit p a:hover {
  color: #282931;
}
@media screen and (max-width: 786px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image #photo-credit {
    position: static !important;
    padding-left: 36px !important;
  }
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(1),
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(1),
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(1),
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(1) {
    padding-left: 0px !important;
    padding-right: calc(1.04vw + 9.5px) !important;
    padding-left: 6px !important;
    margin-right: 3px;
    padding-top: 12px !important;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(1),
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(1),
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(1),
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image:nth-child(1) {
    padding-top: 8px !important;
  }
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
  display: flex !important;
  justify-content: center;
  padding-top: 40px !important;
  padding-bottom: 70px !important;
}
@media (max-width: 768px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-left: calc(4.67vw + 8px) !important;
    padding-right: calc(4.67vw + 8px) !important;
    padding-top: 40px !important;
    padding-bottom: 70px !important;
  }
}
@media (min-width: 550px) {
}
@media (min-width: 650px) {
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p {
  flex-grow: 0;
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p a,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p a,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p a,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p a {
  text-decoration-thickness: 1px !important;
  text-underline-offset: 6px;
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p a:hover,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p a:hover,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p a:hover,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p a:hover {
  text-decoration: underline !important;
  text-decoration-thickness: 1px !important;
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before,
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after {
  position: absolute;
  content: "\2014";
  left: calc(50% - 23px);
  font-family: 'BonnefantenBlock', Arial, sans-serif;
  font-size: 15vw;
  color: #b6a782;
  text-align: center;
  line-height: 2vw;
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before,
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after {
    font-size: 8vw;
    left: calc(50% - 26px);
  }
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before,
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after {
    left: calc(50% - 40px);
  }
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before {
  top: 19px;
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:before {
    top: 8px;
  }
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after {
  bottom: 25px;
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after {
    bottom: 20px;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:after {
    bottom: 10px;
  }
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    width: 100%;
    padding-left: calc(5% + 6px);
    padding-right: calc(10% + 3px);
  }
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
  justify-content: center !important;
  height: 100%;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-top: 10% !important;
    padding-bottom: 10% !important;
  }
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text span,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text span,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text span,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text span {
  padding: 0% !important;
  font-size: 1.25em !important;
  line-height: 30px !important;
  margin-top: 30px !important;
  margin-bottom: 35px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  font-family: 'StyreneRegular', Arial, sans-serif;
  letter-spacing: 0em;
  color: var(--bonnefanten-primary-text) !important;
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text span,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text span,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text span,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text span {
    font-size: 1.8vw !important;
    line-height: 1.45em !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 8px !important;
    margin-bottom: 35px !important;
  }
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image),
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer,
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text span,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text span,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text span,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text span {
    line-height: 1.4em !important;
    margin-top: 8px !important;
    margin-bottom: 7px !important;
  }
}
@media (min-width: 1200px) {
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image) em,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image) em,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image) em,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text p:not(.image) em,
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer em,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer em,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer em,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text .DraftEditor-editorContainer em,
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text span em,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text span em,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text span em,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text span em {
  font-style: italic;
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ::after,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ::after,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ::after,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text ::after {
  content: "";
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text > *,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text > *,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text > *,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text > * {
  flex-grow: 0 !important;
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2)::after,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2)::after,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2)::after,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2)::after {
  content: "\2013" !important;
}
#view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2)::before,
#page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2)::before,
#view .block-editor-ImageAndTextBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2)::before,
#page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2)::before {
  content: "\2013" !important;
}
@media (min-width: 650px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2),
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2),
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2),
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.two.column.grid > .grid-block-text:nth-child(2) {
    padding-left: 10% !important;
    padding-right: calc(5% + 5px) !important;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.two.column.grid > .column.grid-block-image:nth-child(2) img,
  #page-edit #quote-block-wrapper.ImageAndTextBlock .ui.stackable.stretched.two.column.grid > .column.grid-block-image:nth-child(2) img,
  #view .block-editor-ImageAndTextBlock .ui.stackable.stretched.two.column.grid > .column.grid-block-image:nth-child(2) img,
  #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.two.column.grid > .column.grid-block-image:nth-child(2) img {
    padding-left: 0px !important;
    margin-right: 0px !important;
    padding-right: calc(1.04vw + 6px) !important;
  }
}
#page-edit .block-editor-ImageAndTextBlock {
  width: 90vw !important;
  padding-left: 100px !important;
}
#page-edit .block-editor-ImageAndTextBlock .block-add-button,
#page-edit .block-editor-ImageAndTextBlock .remove-block-button,
#page-edit .block-editor-ImageAndTextBlock .toolbar {
  display: none;
}
#page-edit .no-image-wrapper .ui.input {
  overflow: hidden;
}
.has-sidebar #page-edit .block-editor-ImageAndTextBlock {
  padding-left: 150px !important;
  width: 70vw !important;
  margin-right: -40vw !important;
  margin-left: -40vw !important;
}
@media screen and (max-width: 1150px) {
  .has-sidebar #page-edit .block-editor-ImageAndTextBlock {
    width: 65vw !important;
  }
  .has-sidebar #page-edit .block-editor-ImageAndTextBlock .ui.stackable.stretched.column.grid {
    padding-left: 0px !important;
  }
}
@media screen and (max-width: 1100px) {
  .has-sidebar #page-edit .block-editor-ImageAndTextBlock {
    padding-left: 120px !important;
    width: 60vw !important;
  }
}
@media screen and (max-width: 1000px) {
  .has-sidebar #page-edit .block-editor-ImageAndTextBlock {
    padding-left: 50px !important;
  }
}
@media screen and (max-width: 768px) {
  .has-sidebar #page-edit .block-editor-ImageAndTextBlock {
    padding-left: 0px !important;
  }
}
@media (min-width: 768px) {
  #page-document .content-container #quote-block-wrapper.ImageAndTextBlock:first-child .ui.stackable.stretched.column.grid {
    margin-top: 0px !important;
  }
}
