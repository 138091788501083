.block.listing.whatsonview {
  margin-top: 48px;
  margin-bottom: 0px;
}
@media screen and (min-width: 1400px) {
  .block.listing.whatsonview {
    margin-bottom: 0px;
  }
}
.block.listing.whatsonview .header-quotes-wrapper {
  display: block !important;
}
.block.listing.whatsonview .header-quotes-wrapper .quote-top-left.quote-bonnefanten {
  left: 13px;
  top: 12px;
}
.block.listing.whatsonview .header-quotes-wrapper .quote-top-right.quote-bonnefanten {
  right: 13px;
  top: 12px;
}
@media screen and (max-width: 992px) {
  .block.listing.whatsonview .header-quotes-wrapper .quote-bonnefanten {
    display: none;
  }
}
.view-viewview .block.listing.whatsonview {
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw !important;
  max-width: initial !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
}
@media screen and (min-width: 992px) {
  .whatson-template {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .whatson-template {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.whatson-template .listing-slider > div {
  width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .whatson-template .listing-slider {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .whatson-template .listing-slider > div {
    width: 50% !important;
    margin-bottom: 28px;
  }
}
@media screen and (min-width: 992px) {
  .whatson-template .content-wrapper {
    padding-bottom: 10px;
  }
}
.whatson-template .content {
  justify-content: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-left: 32.5px;
  padding-right: 32.5px;
  margin-top: 10px;
  margin-bottom: 45px;
}
@media screen and (min-width: 768px) {
  .whatson-template .content {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    justify-content: flex-start;
  }
}
@media screen and (min-width: 992px) {
  .whatson-template .content {
    justify-content: center;
    min-height: 300px;
    flex-direction: row;
    padding-left: 14px;
    padding-right: 14px;
    margin-bottom: 57px;
  }
}
@media screen and (min-width: 1480px) {
  .whatson-template .content {
    margin-bottom: 55px;
    padding-top: 3px;
  }
}
.whatson-template .card-left-part {
  width: 100%;
}
@media screen and (min-width: 992px) {
  .whatson-template .card-left-part {
    width: calc(60% - 20px);
    padding: 5px;
    padding-left: 75px;
  }
}
@media screen and (min-width: 1480px) {
  .whatson-template .card-left-part {
    padding-right: 7px;
  }
}
.whatson-template .card-right-part {
  width: 100%;
}
@media screen and (min-width: 992px) {
  .whatson-template .card-right-part {
    width: 40%;
    padding: 0 20px ;
  }
}
@media screen and (min-width: 992px) {
  .whatson-template .card-right-part {
    padding-left: 18px;
  }
}
.whatson-template .listing-image {
  width: 100%;
}
.whatson-template .listing-image img {
  max-width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}
.whatson-template .buttons {
  position: absolute;
  z-index: 0;
  top: calc(50% - 37px);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .whatson-template .buttons {
    top: calc(50% - 45px);
  }
}
.whatson-template button > .icon {
  background-color: transparent;
}
.whatson-template .left-button {
  position: absolute;
  z-index: 0;
  top: calc(50% - 60px);
  left: 6.5px;
  margin-top: 1px;
  margin-left: 14px;
  width: 16px;
  height: 30px;
  background-color: transparent;
  border: none;
  z-index: 1;
}
.whatson-template .left-button svg {
  fill: var(--bonnefanten-primary);
  height: 42.5px;
  opacity: 0.75;
  transition: opacity 0.1s ease-in;
}
.whatson-template .left-button svg:hover {
  opacity: 1;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .whatson-template .left-button {
    top: calc(50% - 45px);
    width: 62px;
    margin-left: 10px;
    left: 10px;
  }
}
@media screen and (min-width: 992px) {
  .whatson-template .left-button {
    top: calc(50% - 7px);
    left: 22px;
  }
}
@media screen and (min-width: 1480px) {
  .whatson-template .left-button {
    top: calc(50% - 14px);
    left: 20px;
    width: 70px;
    height: 85px;
    margin-left: 64px;
  }
}
.whatson-template .right-button {
  position: absolute;
  z-index: 0;
  top: calc(50% - 60px);
  right: 42px;
  z-index: 5;
  margin-top: 1px;
  margin-right: -2px;
  width: 16px;
  height: 30px;
  background-color: transparent;
  border: none;
}
.whatson-template .right-button svg {
  fill: var(--bonnefanten-primary);
  height: 42.5px;
  opacity: 0.75;
  transition: opacity 0.1s ease-in;
}
.whatson-template .right-button svg:hover {
  opacity: 1;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .whatson-template .right-button {
    top: calc(50% - 45px);
    margin-left: 10px;
    right: 18px;
    width: 62px;
  }
}
@media screen and (min-width: 992px) {
  .whatson-template .right-button {
    top: calc(50% - 7px);
    right: 32px;
  }
}
@media screen and (min-width: 1480px) {
  .whatson-template .right-button {
    top: calc(50% - 14px);
    right: 26px;
    width: 70px;
    height: 85px;
    margin-right: 58px;
  }
}
.whatson-template .text-button.btn-block.more-info {
  line-height: 25px !important;
  margin-right: 15px !important;
  padding: 5px 14px !important;
  font-size: 19px !important;
}
.whatson-template .text-button.btn-block.more-info a {
  text-decoration: none !important;
  color: var(--bonnefanten-bg) !important;
}
@media screen and (min-width: 768px) {
  .whatson-template .text-button.btn-block.more-info {
    font-size: 19px !important;
  }
}
@media screen and (min-width: 1400px) {
  .whatson-template .text-button.btn-block.more-info {
    margin-left: -2px;
  }
}
.whatson-template .text-button.btn-block:hover {
  background-color: #B6A782 !important;
  border-color: #B6A782 !important;
  font-size: 19px;
}
.whatson-template .plone-item-title {
  margin-top: 0px;
}
@media screen and (max-width: 768px) {
  .whatson-template .plone-item-title {
    margin-bottom: 0px;
  }
}
.whatson-template .plone-item-title a {
  border-bottom: 0px !important;
  text-decoration: none !important;
}
.whatson-template .plone-item-title a:hover {
  text-decoration: underline !important;
  -webkit-text-decoration-color: var(--bonnefanten-primary) !important;
          text-decoration-color: var(--bonnefanten-primary) !important;
  text-decoration-thickness: 2px !important;
  text-underline-offset: 3px !important;
}
.whatson-template .plone-item-title h2 {
  border-bottom: 1px solid transparent;
  color: var(--bonnefanten-primary) !important;
  font-family: 'StyreneBold', Arial, sans-serif;
  margin-top: 8px !important;
  font-weight: 400;
  font-size: 26px;
  letter-spacing: 0em;
  line-height: 30px;
  text-transform: none;
  margin-left: -0.5px !important;
  margin-bottom: 8px !important;
}
@media screen and (min-width: 992px) {
  .whatson-template .plone-item-title h2 {
    font-size: 1.8vw;
    margin-top: 12px !important;
    margin-left: 0px;
    margin-bottom: 16px !important;
    max-width: 90%;
  }
}
@media screen and (min-width: 1480px) {
  .whatson-template .plone-item-title h2 {
    line-height: 35px;
    margin-top: 7px !important;
    margin-bottom: 29px !important;
    font-size: 30px;
  }
}
.whatson-template .hero-dates {
  font-family: 'StyreneRegular', Arial, sans-serif;
  letter-spacing: 0em;
  color: #b6a782 !important;
  font-size: 20px !important;
  line-height: 25px !important;
}
.whatson-template .event-label {
  padding-top: 8px !important;
}
@media screen and (min-width: 992px) {
  .whatson-template .event-label {
    padding-top: 0px !important;
  }
}
@media screen and (min-width: 992px) {
  .whatson-template .description {
    margin-top: 24px !important;
  }
}
@media screen and (min-width: 1480px) {
  .whatson-template .description {
    margin-top: 28px !important;
  }
}
.whatson-template .description p {
  color: #b6a782 !important;
  font-size: 19px;
  line-height: 25px !important;
  overflow: hidden;
  margin-bottom: 25px !important;
}
@media screen and (max-width: 992px) {
  .whatson-template .description p {
    margin-top: 3px !important;
  }
}
@media screen and (min-width: 992px) {
  .whatson-template .description p {
    font-size: 19px !important;
    max-width: 90%;
  }
}
@media screen and (min-width: 1200px) {
  .whatson-template .description p {
    margin-bottom: 27px !important;
  }
}
@media screen and (min-width: 1480px) {
  .whatson-template .description p {
    margin-top: 10px;
    margin-bottom: 25px !important;
  }
}
.whatson-template .nutezien-header {
  margin-top: 15px;
  margin-bottom: 42px;
  margin-left: -22px;
}
@media screen and (min-width: 768px) {
  .whatson-template .nutezien-header {
    margin-bottom: 34px;
    margin-left: 12px;
    margin-top: 0px;
  }
}
@media screen and (min-width: 992px) {
  .whatson-template .nutezien-header {
    margin-bottom: 52px;
    margin-left: 9px;
    margin-top: 15px;
  }
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 1480px) {
}
.whatson-template .nutezien-header h3 {
  line-height: 1.2;
  margin-top: 8px;
  color: #b6a782 !important;
  font-family: 'BonnefantenBlock', Arial, sans-serif;
  text-align: center;
  margin-top: 25px;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0em;
  padding-right: 11px;
  font-size: 11vw !important;
  line-height: 10vw;
}
@media screen and (min-width: 768px) {
  .whatson-template .nutezien-header h3 {
    font-size: 8vw !important;
    text-align: left;
    line-height: 10vw;
    margin-top: 0px !important;
  }
}
@media screen and (min-width: 992px) {
  .whatson-template .nutezien-header h3 {
    padding-left: 0%;
    margin-top: 25px;
  }
}
@media screen and (min-width: 1400px) {
  .whatson-template .nutezien-header h3 {
    font-size: 110px !important;
    line-height: 135px;
  }
}
.view-editview .block-editor-listing {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
