@media (min-width: 768px) and (max-width: 992px) {
  .section-search #page-search {
    width: 100% !important;
    padding-left: 35px;
    padding-right: 35px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .section-search #page-search {
    width: 90% !important;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.section-search #content {
  margin-right: 3px;
  margin-left: 3px;
}
@media screen and (min-width: 768px) {
  .section-search #content {
    margin-left: -4px;
    margin-right: -3px;
  }
}
.section-search .searchbar {
  display: flex;
  flex-direction: column;
  width: auto;
}
@media screen and (min-width: 768px) {
  .section-search .searchbar {
    flex-direction: row;
  }
}
.section-search .field.searchbox {
  display: flex;
  flex-direction: column;
  width: auto;
  padding-left: 0% !important;
  margin-bottom: 15px !important;
}
@media screen and (min-width: 768px) {
  .section-search .field.searchbox {
    flex-direction: row;
  }
}
.section-search .ui.form {
  flex-grow: 1;
}
.section-search .ui.form .ui.input {
  border: 2px solid var(--bonnefanten-primary);
  width: 100%;
  margin-bottom: 10px;
}
.section-search .ui.form .ui.input input {
  padding-left: 18px !important;
  font-size: 17px !important;
}
.section-search .ui.form .ui.input input::-moz-placeholder {
  font-size: 17px !important;
}
.section-search .ui.form .ui.input input::placeholder {
  font-size: 17px !important;
}
@media screen and (min-width: 768px) {
  .section-search .ui.form .ui.input {
    margin-bottom: 0px;
  }
}
.section-search input#search-input {
  width: 100% !important;
  font-family: 'StyreneBold', Arial, sans-serif;
  font-size: 17px;
  padding: 14px 20px !important;
}
@media (min-width: 992px) {
  .section-search input#search-input {
    padding: 14.5px 20px !important;
  }
}
.section-search .text-input-facet input::-moz-placeholder {
  font-size: 17px;
}
.section-search .text-input-facet input::placeholder {
  font-size: 17px;
}
.section-search #search-button.Search-main-button {
  border-radius: 0%;
  color: var(--bonnefanten-bg) !important;
  background-color: var(--bonnefanten-primary) !important;
  -webkit-clip-path: polygon(0% 10px, 10px 0%, calc(100%) 0%, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0 calc(100%));
          clip-path: polygon(0% 10px, 10px 0%, calc(100%) 0%, 100% 10px, 100% calc(100% - 10px), calc(100% - 10px) 100%, 10px 100%, 0 calc(100%));
  padding: 15px 28px;
  border: 2px solid var(--bonnefanten-primary) !important;
  font-family: 'StyreneBold', Arial, sans-serif;
  font-size: 17px;
  line-height: 1.4;
  text-align: center;
}
@media screen and (min-width: 992px) {
  .section-search #search-button.Search-main-button {
    margin-left: 10px;
    padding: 14.5px 27px !important;
  }
}
@media screen and (min-width: 1300px) {
}
.section-search #search-button.Search-main-button:hover {
  background-color: var(--bonnefanten-bg) !important;
  color: var(--bonnefanten-primary) !important;
  -webkit-clip-path: none;
          clip-path: none;
  border: 2px solid var(--bonnefanten-primary) !important;
}
.section-search .advanced-search-link {
  margin-top: -1px;
  margin-left: 1px;
  margin-bottom: 25px;
  text-underline-offset: 4px;
}
@media screen and (min-width: 1200px) {
  .section-search .advanced-search-link {
    margin-bottom: 5px !important;
  }
}
.section-search .advanced-search-link a {
  color: #b6a782 !important;
}
