table.slate-table {
  width: 100%;
  border: 0.025rem solid #826a6a;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-collapse: collapse;
  border-spacing: 30px;
}
table.slate-table th,
table.slate-table td {
  padding: 0.5rem;
  border: 0.05rem solid #826a6a;
  vertical-align: middle;
}
table.slate-table th {
  border-bottom: 0.15rem solid #826a6a;
}
table.slate-table-block.sortable tr th > * {
  display: inline-block;
}
