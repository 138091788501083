.contenttype-author #page-search-title {
  display: none;
}
.contenttype-author #object-block {
  margin-top: 159px;
}
@media screen and (max-width: 992px) {
  .contenttype-author #object-block {
    margin-top: 142px;
  }
}
@media screen and (max-width: 768px) {
  .contenttype-author #object-block {
    margin-top: 7px;
  }
}
.contenttype-author #object-block .ui.container {
  width: auto !important;
}
.contenttype-author #object-block #page-search-artwork {
  max-width: none !important;
  padding-left: 11px;
  padding-right: 11px;
  width: auto;
  margin: 4px !important;
}
@media screen and (max-width: 992px) {
  .contenttype-author #object-block #page-search-artwork {
    padding-left: 26px;
    padding-right: 26px;
  }
}
@media screen and (max-width: 768px) {
  .contenttype-author #object-block #page-search-artwork {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.contenttype-author #object-block #page-search-artwork img {
  width: 100%;
}
.contenttype-author .page-search-title {
  margin-bottom: 56px;
  text-align: center;
}
@media screen and (max-width: 992px) {
  .contenttype-author .page-search-title {
    margin-bottom: 29px;
  }
}
@media screen and (max-width: 768px) {
  .contenttype-author .page-search-title {
    margin-bottom: 27px;
  }
}
.contenttype-author .page-search-title h1 {
  margin-top: 10px;
  color: #b6a782;
  font-size: 4vw;
}
@media screen and (max-width: 992px) {
  .contenttype-author .page-search-title h1 {
    font-size: 8vw;
  }
}
@media screen and (max-width: 768px) {
  .contenttype-author .page-search-title h1 {
    font-size: 6vw;
  }
}
.contenttype-author .SeeMoreItem {
  height: auto;
  box-sizing: border-box;
  padding: 20px;
  -moz-column-break-inside: avoid;
       break-inside: avoid;
}
@media screen and (max-width: 768px) {
  .contenttype-author .SeeMoreItem {
    padding: 0px;
    padding-bottom: 40px;
  }
}
.contenttype-author .SeeMoreItem a {
  font-family: 'StyreneBold', Arial, sans-serif;
  text-decoration: none !important;
}
.contenttype-author .SeeMoreItem a:hover {
  text-decoration: underline !important;
}
.contenttype-author .SeeMoreItem img {
  width: 100% !important;
  margin-bottom: 8px !important;
}
@media screen and (min-width: 768px) {
  .contenttype-author .SeeMoreItem img {
    margin-bottom: 5px !important;
  }
}
.contenttype-author .SeeMoreItem .description {
  padding-top: 10px;
}
.contenttype-author .SeeMoreItem .description p {
  margin-bottom: 5px;
}
@media screen and (max-width: 768px) {
  .contenttype-author .SeeMoreItem .description {
    padding-top: 10px;
  }
  .contenttype-author .SeeMoreItem .description p {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 992px) {
  .contenttype-author .SeeMoreItem .description {
    padding-top: 10px;
  }
  .contenttype-author .SeeMoreItem .description p {
    margin-bottom: 5px !important;
  }
}
.contenttype-author .SeeMoreItem .item-description {
  margin-bottom: 5px;
  color: #575756;
  font-family: 'StyreneRegular', Arial, sans-serif;
  font-size: 19px;
  letter-spacing: 0em;
  line-height: 25px;
}
.contenttype-author .item_title {
  color: var(--bonnefanten-primary, #f9423a);
  font-family: 'StyreneBold', Arial, sans-serif;
  font-size: 26px;
  line-height: 30px;
  text-decoration: none !important;
}
@media screen and (max-width: 992px) {
  .contenttype-author .item_title {
    padding-top: 3px;
  }
}
@media screen and (max-width: 768px) {
  .contenttype-author .item_title {
    padding-top: 0px;
  }
}
.contenttype-author #main .description-wrapper {
  padding-bottom: 0.1px !important;
  padding-top: 0px !important;
}
.documentDescription.author:not(.hero-description) {
  font-size: 38px !important;
  font-family: 'StyreneBold', Arial, sans-serif !important;
  margin: 5px !important;
}
