#main .ui.basic.segment.content-area main {
  transform: none !important;
}
#view .text-wrapper,
#page-edit .text-wrapper {
  position: absolute;
  bottom: 0%;
  width: 100%;
}
#view #sliding-block-wrapper.SlidingTextBlock,
#page-edit #sliding-block-wrapper.SlidingTextBlock,
#view .block-editor-SlidingTextBlock,
#page-edit .block-editor-SlidingTextBlock {
  transform: none !important;
  position: relative;
  display: block;
  width: 100vw;
  right: 50% !important;
  left: 50% !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
  padding: 0% !important;
}
#view #sliding-block-wrapper.SlidingTextBlock .block.SlidingTextBlock::before,
#page-edit #sliding-block-wrapper.SlidingTextBlock .block.SlidingTextBlock::before,
#view .block-editor-SlidingTextBlock .block.SlidingTextBlock::before,
#page-edit .block-editor-SlidingTextBlock .block.SlidingTextBlock::before {
  z-index: 0;
}
#view #sliding-block-wrapper.SlidingTextBlock .background-image,
#page-edit #sliding-block-wrapper.SlidingTextBlock .background-image,
#view .block-editor-SlidingTextBlock .background-image,
#page-edit .block-editor-SlidingTextBlock .background-image {
  transform: none !important;
  padding: 0%;
  -o-object-fit: cover !important;
     object-fit: cover !important;
  position: sticky;
  position: -webkit-sticky;
  --webkit-backface-visibility: hidden;
  --webkit-perspective: 1000;
  top: 0%;
  left: 0%;
  width: 100%;
  background-position: calc(50% + 0px);
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (min-width: 992px) {
  #view #sliding-block-wrapper.SlidingTextBlock .background-image,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .background-image,
  #view .block-editor-SlidingTextBlock .background-image,
  #page-edit .block-editor-SlidingTextBlock .background-image {
    background-attachment: fixed !important;
  }
}
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image,
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image,
#view .block-editor-SlidingTextBlock .column.grid-block-image,
#page-edit .block-editor-SlidingTextBlock .column.grid-block-image,
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined,
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined,
#view .block-editor-SlidingTextBlock .column.grid-block-undefined,
#page-edit .block-editor-SlidingTextBlock .column.grid-block-undefined {
  width: 100%;
  /* Your styles here */
}
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image img,
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image img,
#view .block-editor-SlidingTextBlock .column.grid-block-image img,
#page-edit .block-editor-SlidingTextBlock .column.grid-block-image img,
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined img,
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined img,
#view .block-editor-SlidingTextBlock .column.grid-block-undefined img,
#page-edit .block-editor-SlidingTextBlock .column.grid-block-undefined img {
  width: 100px;
}
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image #photo-credit span,
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image #photo-credit span,
#view .block-editor-SlidingTextBlock .column.grid-block-image #photo-credit span,
#page-edit .block-editor-SlidingTextBlock .column.grid-block-image #photo-credit span,
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined #photo-credit span,
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined #photo-credit span,
#view .block-editor-SlidingTextBlock .column.grid-block-undefined #photo-credit span,
#page-edit .block-editor-SlidingTextBlock .column.grid-block-undefined #photo-credit span {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0;
}
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image #photo-credit > :nth-child(2),
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-image #photo-credit > :nth-child(2),
#view .block-editor-SlidingTextBlock .column.grid-block-image #photo-credit > :nth-child(2),
#page-edit .block-editor-SlidingTextBlock .column.grid-block-image #photo-credit > :nth-child(2),
#view #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined #photo-credit > :nth-child(2),
#page-edit #sliding-block-wrapper.SlidingTextBlock .column.grid-block-undefined #photo-credit > :nth-child(2),
#view .block-editor-SlidingTextBlock .column.grid-block-undefined #photo-credit > :nth-child(2),
#page-edit .block-editor-SlidingTextBlock .column.grid-block-undefined #photo-credit > :nth-child(2) {
  padding-left: 4px;
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid {
  transform: none !important;
  flex-direction: column !important;
  justify-content: center;
  height: 100%;
  padding: 0% !important;
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
  width: 100%;
  padding: 0px 17px 25px 17px !important;
}
@media (min-width: 768px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
    padding-left: 30px !important;
    padding-right: 20px !important;
    padding-bottom: 0px !important;
    margin: 0 auto;
    max-width: 82.5%;
  }
}
@media (min-width: 992px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
    max-width: 80%;
    padding-right: 35px !important;
    padding-left: 48px !important;
    padding-bottom: 18px !important;
  }
}
@media (min-width: 1200px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
    padding-bottom: 7px !important;
  }
}
@media (min-width: 1400px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
    padding-bottom: 8px !important;
  }
}
@media (min-width: 1600px) {
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3),
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3),
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3),
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) {
  padding-bottom: 0px !important;
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) .public-DraftEditorPlaceholder-root:after,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) .public-DraftEditorPlaceholder-root:after,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) .public-DraftEditorPlaceholder-root:after,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) .public-DraftEditorPlaceholder-root:after {
  padding: 0% !important;
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span {
  padding: 0% !important;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  font-family: 'StyreneRegular', Arial, sans-serif;
  letter-spacing: 0em;
  text-transform: none;
  font-size: 5.5vw !important;
  margin-bottom: 18px !important;
  line-height: 41.25px !important;
  color: #fedacd !important;
}
@media (min-width: 768px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span {
    font-size: 3vw !important;
    margin-bottom: 16px !important;
  }
}
@media (min-width: 992px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span {
    color: #fedacd !important;
    font-size: 2.1vw !important;
    margin-top: 8px !important;
    line-height: 1.8vw !important;
    margin-bottom: 12px !important;
  }
}
@media (min-width: 1600px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) p,
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(3) span {
    line-height: 1.4em !important;
    margin-bottom: 10px !important;
  }
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-inner,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-inner,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-inner,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-inner {
  padding-top: 25px;
  color: #fedacd;
}
@media (min-width: 768px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
    padding: 0px 48px !important;
    padding-bottom: 150px !important;
  }
}
@media (min-width: 1200px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column.grid-block-image {
    padding-top: 0px !important;
    padding-bottom: 160px !important;
  }
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column span,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .column span,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column span,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .column span {
  width: 100%;
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid h2,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid h2,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid h2,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid h2 {
  padding: 0% !important;
  color: #fedacd !important;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  font-family: 'StyreneRegular', Arial, sans-serif;
  letter-spacing: 0em;
  text-transform: none;
  font-size: 5.5vw !important;
  margin-bottom: 18px !important;
  line-height: 41.25px !important;
}
@media (min-width: 768px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid h2,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid h2 {
    font-size: 3vw !important;
    margin-bottom: 16px !important;
  }
}
@media (min-width: 992px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid h2,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid h2 {
    font-size: 2.1vw !important;
    margin-top: 8px !important;
    line-height: 1.8vw !important;
    margin-bottom: 18px !important;
  }
}
@media (min-width: 1600px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid h2,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid h2,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid h2 {
    line-height: 1.4em !important;
    margin-bottom: 10px !important;
  }
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid p,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid p,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid p,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid p,
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
  padding: 0% !important;
  font-size: 20px !important;
  line-height: 1.5em !important;
  margin-top: 0px !important;
  margin-bottom: 35px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  font-family: 'StyreneRegular', Arial, sans-serif;
  letter-spacing: 0em;
  color: #fedacd;
}
@media (min-width: 768px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid p,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid p,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid p,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid p,
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
    font-size: 2.5vw !important;
    line-height: 1.65em !important;
  }
}
@media (min-width: 992px) {
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid p,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid p,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid p,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid p,
  #view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
    color: #fedacd;
    font-size: 2.9vw !important;
    line-height: 1.4em !important;
    margin-top: 8px !important;
    margin-bottom: 17px !important;
    font-weight: 500;
  }
}
#view #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid p em,
#page-edit #sliding-block-wrapper.SlidingTextBlock .ui.stackable.stretched.column.grid p em,
#view .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid p em,
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid p em {
  font-style: italic;
}
#page-edit .block-editor-SlidingTextBlock {
  width: 90vw !important;
  padding-left: 100px !important;
}
#page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid {
  flex-direction: column-reverse !important;
}
#page-edit .block-editor-SlidingTextBlock .block-add-button,
#page-edit .block-editor-SlidingTextBlock .remove-block-button,
#page-edit .block-editor-SlidingTextBlock .toolbar {
  display: none;
}
.has-sidebar #page-edit .block-editor-SlidingTextBlock {
  padding-left: 150px !important;
  width: 70vw !important;
  margin-right: -40vw !important;
  margin-left: -40vw !important;
}
@media screen and (max-width: 1150px) {
  .has-sidebar #page-edit .block-editor-SlidingTextBlock {
    width: 65vw !important;
  }
  .has-sidebar #page-edit .block-editor-SlidingTextBlock .ui.stackable.stretched.column.grid {
    padding-left: 0px !important;
  }
}
@media screen and (max-width: 1100px) {
  .has-sidebar #page-edit .block-editor-SlidingTextBlock {
    padding-left: 120px !important;
    width: 60vw !important;
  }
}
@media screen and (max-width: 1000px) {
  .has-sidebar #page-edit .block-editor-SlidingTextBlock {
    padding-left: 50px !important;
  }
}
@media screen and (max-width: 768px) {
  .has-sidebar #page-edit .block-editor-SlidingTextBlock {
    padding-left: 0px !important;
  }
}
