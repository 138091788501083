.block.listing.homepagesliderview {
  margin-bottom: 0px !important;
  height: auto;
  min-height: 500px;
  max-height: 900px;
  height: 70vh;
}
@media screen and (min-width: 768px) {
  .block.listing.homepagesliderview {
    height: 90vh;
  }
}
@media (min-width: 1640px) {
  .block.listing.homepagesliderview {
    max-height: 90vh;
  }
}
.view-viewview .block.listing.homepagesliderview {
  position: relative;
  right: 50%;
  left: 50%;
  width: 100vw !important;
  max-width: initial !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
}
.homepage-slider-template {
  min-height: 500px;
  max-height: 900px;
  height: 70vh;
}
@media screen and (min-width: 768px) {
  .homepage-slider-template {
    height: 90vh;
  }
}
@media (min-width: 1640px) {
  .homepage-slider-template {
    max-height: 90vh;
  }
}
.homepage-slider-template .listing-slider {
  height: 100%;
}
.homepage-slider-template .listing-slider img {
  width: 100vw;
  height: 70vh;
  max-height: 900px;
  -o-object-fit: cover;
     object-fit: cover;
  margin-top: auto;
  margin-bottom: auto;
  overflow: hidden;
  min-height: 500px;
}
@media screen and (min-width: 768px) {
  .homepage-slider-template .listing-slider img {
    height: 90vh;
  }
}
@media (min-width: 1640px) {
  .homepage-slider-template .listing-slider img {
    max-height: 90vh;
  }
}
.homepage-slider-template .content-wrapper {
  position: relative;
  height: 100%;
}
.homepage-slider-template .buttons {
  position: absolute;
  top: calc(50% - 33px);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .homepage-slider-template .buttons {
    top: calc(50% - 45px);
  }
}
.homepage-slider-template .buttons svg {
  fill: var(--bonnefanten-primary);
}
@media screen and (max-width: 470px) {
  .homepage-slider-template .buttons svg {
    width: 25px;
  }
}
.homepage-slider-template .plone-item-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0%;
  width: auto;
  overflow: hidden;
}
.homepage-slider-template .plone-item-card-link {
  margin-left: auto !important;
  margin-right: auto !important;
  text-decoration: none !important;
  max-height: 100%;
  overflow: hidden;
}
.homepage-slider-template .plone-item-card-link > * {
  margin-left: auto !important;
  margin-right: auto !important;
}
.homepage-slider-template button > .icon {
  background-color: transparent;
}
.homepage-slider-template .left-button {
  margin-top: -6px;
  margin-left: -8px;
  width: 62px;
  height: 80px;
  background-color: transparent;
  border: none;
  opacity: 0.6 !important;
}
@media screen and (min-width: 470px) {
  .homepage-slider-template .left-button {
    margin-top: -10px;
    margin-left: 27px;
  }
}
@media screen and (min-width: 768px) {
  .homepage-slider-template .left-button {
    margin-top: 2px;
  }
}
@media screen and (min-width: 1200px) {
  .homepage-slider-template .left-button {
    margin-top: 1px;
  }
}
.homepage-slider-template .left-button:hover {
  opacity: 1 !important;
  cursor: pointer;
}
.homepage-slider-template .right-button {
  margin-top: -6px;
  margin-right: -8px;
  width: 62px;
  height: 80px;
  background-color: transparent;
  border: none;
  opacity: 0.6 !important;
}
@media screen and (min-width: 470px) {
  .homepage-slider-template .right-button {
    margin-top: -10px;
    margin-left: 27px;
    margin-right: 21px;
  }
}
@media screen and (min-width: 768px) {
  .homepage-slider-template .right-button {
    margin-top: 2px;
  }
}
@media screen and (min-width: 1200px) {
  .homepage-slider-template .right-button {
    margin-top: 1px;
  }
}
.homepage-slider-template .right-button:hover {
  opacity: 1 !important;
  cursor: pointer;
}
.homepage-slider-template .title-wrapper {
  position: absolute;
  height: 160px;
  bottom: 0px;
  width: 100vw;
  padding: 0%;
  margin: 0%;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 70%) !important;
}
@media screen and (min-width: 992px) {
  .homepage-slider-template .title-wrapper {
    height: 170px;
  }
}
.homepage-slider-template .title-description {
  margin-top: 10px;
  position: absolute;
  bottom: 0px;
  left: 25px;
}
@media screen and (min-width: 470px) {
  .homepage-slider-template .title-description {
    left: 32px;
  }
}
@media screen and (min-width: 992px) {
  .homepage-slider-template .title-description {
    width: calc(100% - 250px);
  }
}
@media screen and (min-width: 1200px) {
  .homepage-slider-template .title-description {
    margin-left: 24px !important;
    left: 103px;
  }
}
.homepage-slider-template .plone-item-title {
  margin-top: 15px;
  margin-bottom: 5px;
}
.homepage-slider-template .plone-item-title p {
  font-size: 20px;
  color: #b6a782;
  line-height: 28px;
  margin-bottom: 0px;
}
@media screen and (min-width: 992px) {
  .homepage-slider-template .plone-item-title p {
    font-size: 1.8vw;
    line-height: 2.5vw;
    color: #ffffff;
  }
}
@media screen and (min-width: 1480px) {
  .homepage-slider-template .plone-item-title p {
    font-size: 30px;
  }
}
.homepage-slider-template .item-description,
.homepage-slider-template .item-description span {
  font-size: 15px;
  color: #b6a782;
  line-height: 25px;
}
@media screen and (min-width: 992px) {
  .homepage-slider-template .item-description,
  .homepage-slider-template .item-description span {
    color: #ffffff;
    font-size: 17px;
    line-height: 25px !important;
  }
}
.homepage-slider-template .slide-title-date {
  color: var(--bonnefanten-primary) !important;
  text-decoration: none !important;
  font-size: 1.18em;
  line-height: 15px;
  margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
  .homepage-slider-template .slide-title-date {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .homepage-slider-template .slide-title-date {
    font-size: 25px;
    margin-bottom: 10px;
  }
}
.homepage-slider-template .slide-title {
  color: var(--bonnefanten-primary) !important;
  text-decoration: none !important;
  font-size: 28px !important;
  line-height: 32px !important;
  font-family: 'StyreneBold', Arial, sans-serif;
  margin-bottom: 32px;
}
@media screen and (min-width: 768px) {
  .homepage-slider-template .slide-title {
    font-size: 30px !important;
    line-height: 34px !important;
  }
}
@media screen and (min-width: 1200px) {
  .homepage-slider-template .slide-title {
    font-size: 38px !important;
    line-height: 45px !important;
  }
}
.homepage-slider-template .slide-title:hover {
  text-decoration: underline !important;
  -webkit-text-decoration-color: var(--bonnefanten-primary) !important;
          text-decoration-color: var(--bonnefanten-primary) !important;
  text-decoration-thickness: 1px !important;
  text-underline-offset: 5px !important;
}
.homepage-slider-template .quote-top-left,
.homepage-slider-template .quote-top-right {
  top: 12px !important;
}
.view-viewview.contenttype-lrf .homepage-slider-template .buttons svg:hover {
  fill: #b6a782;
}
.view-viewview.contenttype-lrf .homepage-slider-template .buttons .right-button {
  opacity: 0.9 !important;
}
.view-viewview.contenttype-lrf .homepage-slider-template .buttons .left-button {
  opacity: 0.9 !important;
}
.view-editview .block-editor-listing {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
.view-editview p.items-preview {
  display: none;
}
