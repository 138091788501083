.AnnouncementBlock {
  background-color: #FFE700;
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-right: -50vw;
  margin-left: -50vw;
  text-align: center;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.AnnouncementBlock .message {
  margin-left: 40px !important;
  margin-right: 40px !important;
}
.AnnouncementBlock .message h3,
.AnnouncementBlock .message p {
  font-family: StyreneRegular, Arial, sans-serif !important;
  margin: 0 !important;
}
.AnnouncementBlock .message p:nth-child(2) {
  margin-top: 15px !important;
}
.AnnouncementBlock .message h3 {
  font-weight: bold;
}
