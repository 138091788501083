.block.listing.sliderview {
  max-width: 100vw;
}
.collection-slider-template .collection-slider img {
  width: 500px;
  max-height: calc(80vh - 190px) !important;
  height: auto;
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  margin-top: auto;
  margin-bottom: auto;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .collection-slider-template .collection-slider img {
    width: auto;
    max-height: 80vh !important;
  }
}
@media screen and (min-width: 1200px) {
  .collection-slider-template .collection-slider img {
    margin-top: 12px;
    padding-left: 13px;
    padding-right: 13px;
  }
}
@media screen and (min-width: 1400px) {
  .collection-slider-template .collection-slider img {
    max-height: calc(80vh - 210px) !important;
  }
}
.collection-slider-template .content-wrapper {
  position: relative;
}
.collection-slider-template .buttons {
  position: absolute;
  top: calc(50% - 33px);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .collection-slider-template .buttons {
    top: calc(50% - 45px);
  }
}
.collection-slider-template .plone-item-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 46px;
  width: auto;
  height: calc(80vh - 50px);
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .collection-slider-template .plone-item-card {
    padding: 0px 40px;
    height: 80vh;
  }
}
@media screen and (min-width: 992px) {
  .collection-slider-template .plone-item-card {
    padding: 0px 220px;
  }
}
@media screen and (min-width: 1400px) {
}
.collection-slider-template .plone-item-card-link {
  position: relative;
  margin-left: auto !important;
  margin-right: auto !important;
  text-decoration: none !important;
  max-height: 100%;
  overflow: hidden;
}
.collection-slider-template .plone-item-card-link > * {
  margin-left: auto !important;
  margin-right: auto !important;
}
.collection-slider-template button > .icon {
  background-color: transparent;
}
.collection-slider-template .left-button {
  margin-top: 1px;
  margin-left: 3px;
  width: 36px;
  height: 55px;
  background-color: transparent;
  border: none;
  opacity: 0.6 !important;
}
@media screen and (min-width: 768px) {
  .collection-slider-template .left-button {
    width: 62px;
    height: 80px;
    margin-left: 10px;
  }
}
@media screen and (min-width: 1480px) {
  .collection-slider-template .left-button {
    margin-left: 70px;
  }
}
.collection-slider-template .left-button:hover {
  opacity: 1 !important;
  cursor: pointer;
}
.collection-slider-template .right-button {
  margin-top: 1px;
  margin-right: 3px;
  width: 36px;
  height: 55px;
  background-color: transparent;
  border: none;
  opacity: 0.6 !important;
}
@media screen and (min-width: 768px) {
  .collection-slider-template .right-button {
    margin-left: 10px;
    width: 62px;
    height: 80px;
  }
}
@media screen and (min-width: 1480px) {
  .collection-slider-template .right-button {
    margin-right: 70px;
  }
}
.collection-slider-template .right-button:hover {
  opacity: 1 !important;
  cursor: pointer;
}
.collection-slider-template .title-description {
  margin-top: 10px;
}
@media screen and (min-width: 992px) {
  .collection-slider-template .title-description {
    position: absolute;
    bottom: 7px;
    margin-left: 25px !important;
    visibility: hidden;
  }
}
@media screen and (min-width: 992px) {
  .collection-slider-template .title-description {
    margin-left: 33px !important;
  }
}
@media screen and (min-width: 1400px) {
  .collection-slider-template .title-description {
    margin-left: 39px !important;
    bottom: 23px !important;
  }
}
.collection-slider-template .plone-item-card-link:hover .title-description {
  visibility: visible !important;
}
.collection-slider-template .plone-item-title {
  margin-top: 15px;
  margin-bottom: 5px;
}
.collection-slider-template .plone-item-title p {
  font-size: 20px;
  color: #b6a782;
  line-height: 28px;
  margin-bottom: 0px;
}
@media screen and (min-width: 992px) {
  .collection-slider-template .plone-item-title p {
    font-size: 1.8vw;
    line-height: 2.5vw;
    color: #ffffff;
  }
}
@media screen and (min-width: 1480px) {
  .collection-slider-template .plone-item-title p {
    font-size: 30px;
  }
}
@media screen and (min-width: 992px) {
  .collection-slider-template .plone-item-title p:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 1px;
  }
}
.collection-slider-template .item-description,
.collection-slider-template .item-description span {
  font-size: 15px;
  color: #b6a782;
  line-height: 25px;
}
@media screen and (min-width: 992px) {
  .collection-slider-template .item-description,
  .collection-slider-template .item-description span {
    color: #ffffff;
    font-size: 17px;
    line-height: 25px !important;
  }
}
@media screen and (min-width: 992px) {
  .collection-slider-template .item-description span::before {
    content: "— ";
  }
}
.collection-slider-template .collectie-header {
  margin-top: 15px;
  margin-bottom: 49px;
  margin-left: 18px;
}
@media screen and (min-width: 768px) {
  .collection-slider-template .collectie-header {
    margin-bottom: 35px;
    margin-left: 14px;
  }
}
@media screen and (min-width: 992px) {
  .collection-slider-template .collectie-header {
    margin-bottom: 35px;
    margin-left: 11px;
  }
}
@media screen and (min-width: 1200px) {
  .collection-slider-template .collectie-header {
    margin-bottom: 36px;
  }
}
@media screen and (min-width: 1400px) {
  .collection-slider-template .collectie-header {
    margin-bottom: 36px;
  }
}
.collection-slider-template .collectie-header h3 {
  font-size: 11vw !important;
  line-height: 1.2 !important;
  margin-top: 8px;
  color: #b6a782 !important;
  font-family: 'BonnefantenBlock', Arial, sans-serif;
  text-align: left;
  margin-top: 25px;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0em;
}
@media screen and (min-width: 768px) {
  .collection-slider-template .collectie-header h3 {
    font-size: 8vw !important;
    line-height: 10vw !important;
  }
}
@media screen and (min-width: 992px) {
  .collection-slider-template .collectie-header h3 {
    font-size: 8vw !important;
    line-height: 10vw !important;
  }
}
@media screen and (min-width: 1400px) {
  .collection-slider-template .collectie-header h3 {
    font-size: 110px !important;
    line-height: 135px !important;
  }
}
.view-editview .block-editor-listing {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
