@media screen and (min-width: 768px) {
  .masonary-template {
    padding-top: 10px;
  }
}
@media screen and (min-width: 992px) {
  .masonary-template {
    padding-top: 12px;
  }
}
.masonary-template a.plone-item-card-link {
  text-decoration: none !important;
}
.masonary-template a.plone-item-card-link:hover .plone-item-title p {
  text-decoration: underline !important;
  text-decoration-thickness: 1px !important;
  -webkit-text-decoration-color: #575756;
          text-decoration-color: #575756;
  text-underline-offset: 5px !important;
}
.masonary-template .masonry-multiple-column {
  padding-left: 5px;
  padding-right: 5px;
}
@media screen and (min-width: 768px) {
  .masonary-template .masonry-multiple-column {
    padding: 20px;
  }
}
.masonary-template .masonry-multiple-column .plone-item-card img {
  max-width: 100% !important;
  width: 100% !important;
}
.masonary-template .masonry-multiple-column > div {
  padding-bottom: 8px;
}
@media screen and (min-width: 992px) {
  .masonary-template .masonry-multiple-column > div {
    padding-bottom: 17px;
  }
}
.masonary-template .plone-item-title {
  text-decoration: none !important;
  border-bottom: 0px;
  margin-top: 8px;
  margin-bottom: 12.5px;
}
@media screen and (min-width: 992px) {
  .masonary-template .plone-item-title {
    margin-top: 8px;
    margin-bottom: 13px;
  }
}
.masonary-template .title-description p {
  font-size: 19px !important;
  margin-top: 12px !important;
}
@media screen and (max-width: 768px) {
  .masonary-template .title-description p {
    padding-left: 20px;
  }
}
.masonary-template .plone-item-title p {
  color: var(--bonnefanten-primary, #f9423a) !important;
  font-family: 'StyreneBold', Arial, sans-serif !important;
  font-size: 26px !important;
  line-height: 30px !important;
  border-bottom: none !important;
  margin-bottom: 10px;
}
.masonary-template .plone-item-title p:hover {
  border-bottom: 1px solid blue;
}
.masonary-template .item-description span {
  margin-bottom: 5px;
  color: #575756;
  font-family: StyreneRegular, Arial, sans-serif;
  font-size: 17px !important;
  letter-spacing: 0 !important;
  line-height: 25px;
}
@media screen and (max-width: 992px) {
  .masonary-template .item-description span {
    font-size: 17px !important;
  }
}
.masonary-template .item-description {
  font-size: 17px !important;
}
@media screen and (max-width: 992px) {
  .masonary-template .item-description {
    font-size: 17px !important;
  }
}
.masonary-listing-page #view {
  padding-right: 0.5px;
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .masonary-listing-page #view {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .masonary-listing-page #view .documentFirstHeading {
    padding-left: 25px;
  }
}
@media screen and (min-width: 768px) {
}
.masonary-listing-page #view #page-document > .content-container {
  margin-top: 37px;
}
@media screen and (min-width: 992px) {
  .masonary-listing-page #view #page-document > .content-container {
    margin-top: 50px;
  }
}
.view-editview .block-editor-listing {
  width: -moz-fit-content !important;
  width: fit-content !important;
}
