#view #quote-block-wrapper.Quoteblock,
#page-edit #quote-block-wrapper.Quoteblock,
#view .block-editor-Quoteblock,
#page-edit .block-editor-Quoteblock {
  position: relative;
  width: 100vw;
  right: 50% !important;
  left: 50% !important;
  margin-right: -50vw !important;
  margin-left: -50vw !important;
}
@media screen and (max-width: 992px) {
  #view #quote-block-wrapper.Quoteblock,
  #page-edit #quote-block-wrapper.Quoteblock,
  #view .block-editor-Quoteblock,
  #page-edit .block-editor-Quoteblock {
    margin-bottom: 0px;
  }
}
#view #quote-block-wrapper.Quoteblock .block.Quoteblock::before,
#page-edit #quote-block-wrapper.Quoteblock .block.Quoteblock::before,
#view .block-editor-Quoteblock .block.Quoteblock::before,
#page-edit .block-editor-Quoteblock .block.Quoteblock::before {
  z-index: 0;
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid {
  flex-direction: column-reverse;
  justify-content: center;
  height: 100vh;
  padding: 50px 48px 50px !important;
  position: relative;
}
@media screen and (min-width: 768px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid {
    height: 110vh;
  }
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid::before,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid::before,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid::before,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid::before {
  content: "\002B";
  position: absolute;
  top: 61px;
  left: 32.5px;
  font-family: 'BonnefantenBlock', Arial, sans-serif;
  font-size: 15vw;
  color: #b6a782;
}
@media screen and (min-width: 768px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid::before,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid::before,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid::before,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid::before {
    left: 48px;
    top: 59px;
    font-size: 8vw;
  }
}
@media screen and (min-width: 1400px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid::before,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid::before,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid::before,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid::before {
    font-size: 110px;
    top: 71px;
  }
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid::after,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid::after,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid::after,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid::after {
  content: "\002B";
  position: absolute;
  bottom: 21px;
  left: 32.5px;
  font-family: 'BonnefantenBlock', Arial, sans-serif;
  font-size: 15vw;
  color: #b6a782;
}
@media screen and (min-width: 768px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid::after,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid::after,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid::after,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid::after {
    left: 48px;
    bottom: 18px;
    font-size: 8vw;
  }
}
@media screen and (min-width: 1400px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid::after,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid::after,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid::after,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid::after {
    font-size: 110px;
    bottom: 26px;
  }
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-top: 0% !important;
  padding-bottom: 0% !important;
}
@media screen and (min-width: 550px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-left: 3px !important;
  }
}
@media screen and (min-width: 768px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-right: 11px !important;
    padding-left: 11px !important;
  }
}
@media screen and (min-width: 1200px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text {
    padding-right: 23px !important;
    padding-left: 11px !important;
  }
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text.empty p,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text.empty p,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text.empty p,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text.empty p {
  display: none !important;
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column span,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column span,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column span,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column span {
  display: block;
  width: 100%;
  margin-left: 0% !important;
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root {
  width: 100%;
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-inner,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-inner,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-inner,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-inner {
  display: none;
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root:after,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root:after,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root:after,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root:after {
  content: 'Every child is an artist...' !important;
  padding: 25px;
  width: 100%;
  display: block;
  padding: 0% !important;
  font-size: 27.2px !important;
  line-height: 36px !important;
  margin-top: 0px !important;
  margin-bottom: 25px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  font-family: 'StyreneRegular', Arial, sans-serif;
  letter-spacing: 0em;
  color: var(--bonnefanten-primary-text);
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root:after,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root:after,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root:after,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .public-DraftEditorPlaceholder-root:after {
    font-size: 3vw !important;
    line-height: 4.7vw !important;
    margin-top: 0px !important;
    margin-bottom: 10px !important;
  }
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid h2,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid h2,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid h2,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid h2 {
  padding: 0% !important;
  color: var(--bonnefanten-primary-text);
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  font-family: 'StyreneRegular', Arial, sans-serif;
  letter-spacing: 0em;
  text-transform: none;
  font-size: 0.75em !important;
  line-height: 30px !important;
}
@media (min-width: 992px) {
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid p,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid p,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid p,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid p,
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
  padding: 0% !important;
  font-size: 1.6em;
  line-height: 36px;
  margin-top: 0px !important;
  margin-bottom: 25px !important;
  margin-left: -2px !important;
  font-family: 'StyreneRegular', Arial, sans-serif;
  letter-spacing: 0em;
  color: var(--bonnefanten-primary-text);
}
@media (min-width: 768px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid p,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid p,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid p,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid p,
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
    margin-left: 10px !important;
  }
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid p,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid p,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid p,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid p,
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
    font-size: 3vw !important;
    line-height: 3.8vw !important;
    margin-top: 0px !important;
    margin-bottom: 22px !important;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid p,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid p,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid p,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid p,
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
    margin-bottom: 19px !important;
  }
}
@media (min-width: 1400px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid p,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid p,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid p,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid p,
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .DraftEditor-editorContainer {
    margin-bottom: 15px !important;
  }
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) .public-DraftEditorPlaceholder-root:after,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) .public-DraftEditorPlaceholder-root:after,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) .public-DraftEditorPlaceholder-root:after,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) .public-DraftEditorPlaceholder-root:after {
  content: '- Pablo Picasso' !important;
  padding: 0% !important;
  font-size: 0.75em !important;
  color: var(--bonnefanten-primary-text) !important;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
  margin-left: 10px;
  margin-right: 10px !important;
  font-family: 'StyreneRegular', Arial, sans-serif;
  font-style: italic;
  letter-spacing: 0em;
  text-transform: none;
  line-height: 30px !important;
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) .public-DraftEditorPlaceholder-root:after,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) .public-DraftEditorPlaceholder-root:after,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) .public-DraftEditorPlaceholder-root:after,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) .public-DraftEditorPlaceholder-root:after {
    margin-top: 0px !important;
    line-height: 1.8vw !important;
  }
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) .public-DraftEditorPlaceholder-root:after,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) .public-DraftEditorPlaceholder-root:after,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) .public-DraftEditorPlaceholder-root:after,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) .public-DraftEditorPlaceholder-root:after {
  padding: 0% !important;
}
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
#view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
#page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
#view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span {
  padding: 0% !important;
  color: var(--bonnefanten-primary-text) !important;
  margin-top: 7px !important;
  margin-bottom: 15px !important;
  margin-left: 10px;
  margin-right: 10px !important;
  font-family: 'StyreneRegular', Arial, sans-serif;
  letter-spacing: 0em;
  text-transform: none;
  line-height: 30px;
  font-size: 12px !important;
  font-style: italic;
}
@media (min-width: 769px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span {
    margin-top: 5px !important;
  }
}
@media (min-width: 992px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span {
    margin-bottom: 26px !important;
  }
}
@media (min-width: 1200px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span {
    margin-bottom: 29px !important;
  }
}
@media (min-width: 1400px) {
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) p,
  #view #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #page-edit #quote-block-wrapper.Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #view .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span,
  #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid .column.grid-block-text:nth-child(2) span {
    margin-bottom: 3px !important;
  }
}
#view #quote-block-wrapper.Quoteblock .button,
#page-edit #quote-block-wrapper.Quoteblock .button,
#view .block-editor-Quoteblock .button,
#page-edit .block-editor-Quoteblock .button {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  padding-left: 21px;
}
#view #quote-block-wrapper.Quoteblock .button .text-button.btn-block,
#page-edit #quote-block-wrapper.Quoteblock .button .text-button.btn-block,
#view .block-editor-Quoteblock .button .text-button.btn-block,
#page-edit .block-editor-Quoteblock .button .text-button.btn-block {
  line-height: 3.8vw !important;
}
#page-edit .block-editor-Quoteblock {
  width: 90vw !important;
  padding-left: 100px !important;
}
#page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid {
  height: 90vh !important;
}
#page-edit .block-editor-Quoteblock .block-add-button,
#page-edit .block-editor-Quoteblock .remove-block-button,
#page-edit .block-editor-Quoteblock .toolbar {
  display: none;
}
.has-sidebar #page-edit .block-editor-Quoteblock {
  padding-left: 150px !important;
  width: 70vw !important;
  margin-right: -40vw !important;
  margin-left: -40vw !important;
}
@media screen and (max-width: 1150px) {
  .has-sidebar #page-edit .block-editor-Quoteblock {
    width: 65vw !important;
  }
  .has-sidebar #page-edit .block-editor-Quoteblock .ui.stackable.stretched.column.grid {
    padding-left: 0px !important;
  }
}
@media screen and (max-width: 1100px) {
  .has-sidebar #page-edit .block-editor-Quoteblock {
    padding-left: 120px !important;
    width: 60vw !important;
  }
}
@media screen and (max-width: 1000px) {
  .has-sidebar #page-edit .block-editor-Quoteblock {
    padding-left: 50px !important;
  }
}
@media screen and (max-width: 768px) {
  .has-sidebar #page-edit .block-editor-Quoteblock {
    padding-left: 0px !important;
  }
}
